import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

import { populateUniqueTitleIds } from '../../../index';
import { RichText } from '../../atoms/RichText';
import { TChildren } from '../../atoms/RichText/types';
import {
  prepareTableOfContentsStructure,
  TableOfContents,
} from '../../atoms/TableOfContents';
import { Container } from '../../sections/Container';

export interface IFinanceTableProps {
  richText: TChildren;
  sidebarTitle?: string;
  id?: string;
  blockImage?: ImageProps | null;
  isAnchors: boolean;
  isLegalTemplate: boolean;
}

const FinanceTable = (props: IFinanceTableProps): JSX.Element => {
  const {
    richText,
    sidebarTitle,
    id,
    isAnchors = true,
    isLegalTemplate,
    blockImage,
  } = props;
  const richTextWithIds = populateUniqueTitleIds(richText);
  const tableOfContentsStructure =
    richText && isAnchors
      ? prepareTableOfContentsStructure(richTextWithIds)
      : null;
  const [isFixed, setIsFixed] = useState(false);
  const [isTop, setIsTop] = useState(true);

  const contentBlockRef = useRef<HTMLDivElement | null>(null);
  const tocBlockRef = useRef<HTMLDivElement | null>(null);

  // copy the toc block height, make it invisible and always fixed
  const tocBlockShadowRef = useRef<HTMLDivElement | null>(null);

  const h2Styles = clsx(
    isLegalTemplate
      ? 'prose-h2:pb-1 prose-h2:pt-5 prose-h2:text-2xl'
      : 'prose-h2:pb-4 prose-h2:pt-10 prose-h2:text-5xl',
  );

  const pStyles = clsx(
    isLegalTemplate
      ? 'prose-p:text-interface-1000/70'
      : 'prose-p:text-interface-800',
  );

  const listStyles = clsx(isLegalTemplate ? 'prose-ul:py-4 prose-ol:py-4' : '');

  const handleScroll = (): void => {
    if (contentBlockRef.current && tocBlockShadowRef.current) {
      const contentRect = contentBlockRef.current.getBoundingClientRect();
      const tocBlockShadowRect =
        tocBlockShadowRef.current.getBoundingClientRect();
      setIsTop(tocBlockShadowRect.top === contentRect.top);
      setIsFixed(
        tocBlockShadowRect.bottom < contentRect.bottom &&
          tocBlockShadowRect.top !== contentRect.top,
      );
    }
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (tocBlockRef.current && tocBlockShadowRef.current) {
        const tocBlockRect = tocBlockRef.current.getBoundingClientRect();
        tocBlockShadowRef.current.style.height = tocBlockRect.height + 'px';
      }
    });

    if (tocBlockRef.current) {
      observer.observe(tocBlockRef.current);
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const contentBlockClasses = clsx(
    h2Styles,
    'prose-a:text-brand-500 prose-a:transition hover:prose-a:text-brand-550',
    'prose-h1:break-normal prose-h1:pb-4 prose-h1:pt-10 prose-h1:text-5xl 2xl:prose-h1:text-7xl prose-h1:font-semibold prose-h1:leading-normal prose-h1:text-interface-1000 first:prose-h1:pt-0',
    'prose-h2:break-normal prose-h2:font-semibold prose-h2:leading-normal prose-h2:text-interface-1000 first:prose-h2:pt-0',
    'prose-h3:break-normal prose-h3:pb-1 prose-h3:pt-5 prose-h3:text-base prose-h3:font-semibold prose-h3:leading-normal prose-h3:text-interface-1000',
    'prose-h4:break-normal prose-h4:pb-1 prose-h4:pt-5 prose-h4:text-base prose-h4:font-semibold prose-h4:leading-normal prose-h4:text-interface-1000',
    pStyles,
    'prose-p:py-2 prose-p:text-base prose-p:leading-extra-loose',
    'marker:text-base marker:text-brand-500',
    'prose-strong:font-semibold',
    listStyles,
    'prose-ul:grid prose-ul:list-disc prose-ul:gap-2 prose-ul:pl-4',
    'prose-ol:grid prose-ol:list-decimal prose-ol:gap-2 prose-ol:pl-4',
    'prose-li:text-base prose-li:text-interface-800',
    'prose-img:h-auto prose-img:w-full',
    '2xl:max-w-[38.75rem]',
  );

  return (
    <>
      <Container isPadding={false}>
        {blockImage && (
          <div className='relative mb-4 h-[10rem] w-full 2xl:mb-0'>
            <Image
              {...blockImage}
              className={'h-full w-full object-cover object-center'}
            />
          </div>
        )}
      </Container>
      <Container>
        <div className='flex flex-row gap-16'>
          {tableOfContentsStructure && (
            <div ref={tocBlockShadowRef} className='fixed' />
          )}
          {tableOfContentsStructure && (
            <div
              className={clsx(
                'relative hidden w-full min-w-[15.75rem] max-w-[15.75rem] 2xl:flex 2xl:flex-col',
                {
                  'pb-2': !isFixed,
                  '2xl:justify-end': !isFixed && !isTop,
                },
              )}
            >
              <div
                ref={tocBlockRef}
                className={clsx('w-full max-w-[15.75rem]', {
                  '2xl:fixed': isFixed,
                  '2xl:relative': !isFixed,
                })}
              >
                <TableOfContents
                  tableOfContentsStructure={tableOfContentsStructure}
                  title={sidebarTitle}
                />
              </div>
            </div>
          )}
          <div ref={contentBlockRef} className={contentBlockClasses}>
            <RichText key={id} content={richTextWithIds} />
          </div>
        </div>
      </Container>
    </>
  );
};

export default FinanceTable;
