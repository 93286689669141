export const enum EButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  Float = 'Float',
}

export const enum EButtonTheme {
  Primary = 'Primary',
  Secondary = 'Secondary',
  PrimaryOnColour = 'Primary-on-color',
  SecondaryOnColour = 'Secondary-on-colour',
  Tertiary = 'Tertiary',
}

export const enum EButtonVariant {
  Contained = 'Contained',
  Outlined = 'Outlined',
  Text = 'Text',
}
