import { getGaId } from '@front/shared';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { TAction, EActionType } from '../../../../src/types';
import { Button, EButtonSize, EButtonTheme } from '../../atoms/Button';
import {
  Eyebrow,
  EEyebrowVariant,
  EEyebrowSize,
  EEyebrowTheme,
} from '../../atoms/Eyebrow';

export enum EHeroSectionTitlePosition {
  Left = 'left',
  Center = 'center',
}

export enum EHeroSectionTitleTheme {
  Theme1 = 'theme1', // theme numbers should matches design system in figma
  Theme4 = 'theme4',
  Theme5 = 'theme5',
}

export type THeroSectionTitleProps = {
  title: string;
  description?: string;
  actions?: TAction[];
  eyebrowsBelow?: string[];
  eyebrowsAbove?: string[];
  position?: EHeroSectionTitlePosition;
  theme?: EHeroSectionTitleTheme;
  className?: string;
};

export const HeroSectionTitle: React.FC<THeroSectionTitleProps> = ({
  title,
  description,
  eyebrowsBelow,
  eyebrowsAbove,
  actions = [],
  position = EHeroSectionTitlePosition.Center,
  theme = EHeroSectionTitleTheme.Theme1,
  className,
}) => {
  let eyebrowTheme = EEyebrowTheme.Primary;
  let buttonTheme = EButtonTheme.Primary;

  if (theme === EHeroSectionTitleTheme.Theme5) {
    eyebrowTheme = EEyebrowTheme.Light;
    buttonTheme = EButtonTheme.SecondaryOnColour;
  }

  return (
    <div
      className={clsx(
        'mx-auto grid w-full gap-8 lg:mt-0 xl:max-w-[38.75rem] xl:auto-rows-max xl:gap-12',
        className,
      )}
    >
      <div
        className={clsx(
          'mx-auto flex flex-col gap-4 ',
          position === EHeroSectionTitlePosition.Center
            ? 'text-center'
            : 'text-left',
        )}
      >
        {!!eyebrowsAbove?.length && (
          <div>
            {eyebrowsAbove.map(eyebrow => (
              <Eyebrow
                key={eyebrow}
                variant={EEyebrowVariant.Text}
                size={EEyebrowSize.Large}
                theme={eyebrowTheme}
              >
                {eyebrow}
              </Eyebrow>
            ))}
          </div>
        )}
        <h1
          className={clsx(
            'whitespace-pre-line text-7xl font-semibold leading-snug xl:text-8xl xl:leading-tight 2xl:text-9xl',
            {
              'text-interface-1000': theme === EHeroSectionTitleTheme.Theme1,
              'text-interface-50':
                theme === EHeroSectionTitleTheme.Theme4 ||
                theme === EHeroSectionTitleTheme.Theme5,
            },
          )}
        >
          {title}
        </h1>
        {description && (
          <h2
            className={clsx(
              'font-regular leading-extraloose whitespace-pre-line text-base xl:mx-auto xl:max-w-[38.75rem]',
              {
                'text-interface-800': theme === EHeroSectionTitleTheme.Theme1,
                'text-interface-50/80':
                  theme === EHeroSectionTitleTheme.Theme4 ||
                  theme === EHeroSectionTitleTheme.Theme5,
              },
            )}
          >
            {description}
          </h2>
        )}
        {!!eyebrowsBelow?.length && (
          <div>
            {eyebrowsBelow.map(eyebrow => (
              <Eyebrow
                key={eyebrow}
                variant={EEyebrowVariant.Text}
                size={EEyebrowSize.Large}
                theme={eyebrowTheme}
              >
                {eyebrow}
              </Eyebrow>
            ))}
          </div>
        )}
      </div>

      {!!actions.length && (
        <div className='mx-auto flex w-full flex-col justify-center gap-4 xl:flex-row 2xl:max-w-[35rem] 2xl:max-w-[50rem]'>
          {actions.map((action, index) => (
            <div className='grid whitespace-nowrap 2xl:grid' key={index}>
              {action.type === EActionType.ButtonType && (
                <Button
                  theme={buttonTheme}
                  {...action.props}
                  size={EButtonSize.Large}
                  data-ga-id={getGaId(action.text, 'hero', title)}
                />
              )}
              {action.type === EActionType.LinkType && (
                <Link
                  className='flex items-center justify-center gap-2.5 rounded-2xl bg-brand-500 px-10 py-5 text-center text-base font-medium leading-tight text-brand-50/90 transition last:border last:border-brand-500 last:bg-transparent last:text-brand-500 hover:bg-brand-550 hover:text-brand-50'
                  {...action.props}
                  data-ga-id={getGaId(action.text, 'hero', title)}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
