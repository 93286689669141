import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React from 'react';

import {
  EBannerCardLayout,
  EBannerCardBackground,
} from './BannerCard.constants';
import { Card, ECardTextColor, ETitleSize, ICardProps } from '../../atoms/Card';

export type TBannerCardProps = ICardProps & {
  size?: EBannerCardLayout;
  image?: ImageProps | null;
  swapImageSide?: boolean;
  background?: EBannerCardBackground;
  wrapperClassName?: string;
};

const BannerCard = (props: TBannerCardProps): JSX.Element => {
  const {
    size = EBannerCardLayout.BannerCardWithBg,
    image = null,
    swapImageSide = false,
    background = EBannerCardBackground.Light,
    titleSize,
    ...rest
  } = props;

  let cardTextColor = ECardTextColor.Dark;
  let cardBackground = ' bg-surface-100';
  if (background === EBannerCardBackground.Dark) {
    cardTextColor = ECardTextColor.Light;
    cardBackground = ' bg-control-1000';
  } else if (background === EBannerCardBackground.White) {
    cardBackground = ' bg-surface-50';
  } else if (background === EBannerCardBackground.Brand) {
    cardBackground = ' bg-brand-500';
    cardTextColor = ECardTextColor.Light;
  }

  return (
    <div className='h-full flex-1'>
      {size === EBannerCardLayout.BannerCard && (
        <div
          className={clsx(
            'flex flex-col flex-wrap gap-8 lg:items-center lg:gap-18',
            swapImageSide ? 'lg:flex-row-reverse' : 'lg:flex-row',
          )}
        >
          <div className='flex-1 lg:max-w-[49%]'>
            <Card titleSize={titleSize || ETitleSize.Large} {...rest} />
          </div>
          {image && (
            <Image
              {...image}
              className='h-auto w-full flex-1 rounded-2xl object-cover lg:max-w-[50%] lg:rounded-[2rem]'
            />
          )}
        </div>
      )}
      {size === EBannerCardLayout.BannerCardWithBg && (
        <div
          className={clsx(
            'flex flex-col flex-wrap overflow-hidden rounded-3xl lg:items-center lg:gap-4 lg:rounded-[2rem]' +
              cardBackground,
            swapImageSide ? 'lg:flex-row-reverse' : 'lg:flex-row',
          )}
        >
          <div className='flex-1 p-8 lg:max-w-[49%] lg:p-14'>
            <Card
              cardTextColor={cardTextColor}
              titleSize={titleSize || ETitleSize.Large}
              {...rest}
            />
          </div>
          {image && (
            <Image
              {...image}
              className='h-auto w-full flex-1 rounded-xl object-cover lg:max-w-[50%] lg:rounded-[1.25rem]'
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BannerCard;
