import { Header, Jurisdiction, Locale } from '@shared/master-types';
import dynamic from 'next/dynamic';

const HeaderConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "HeaderConnected" */ '../../layout/HeaderConnected'
    ),
);

export interface IHeaderParserProps {
  headers?: Header | string;
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
}

const HeaderParser = (props: IHeaderParserProps): JSX.Element | null => {
  const {
    headers: headerDocs,
    locales,
    jurisdictionList,
    jurisdictionItem,
  } = props;

  if (typeof headerDocs !== 'string') {
    const header = headerDocs?.header && headerDocs?.header[0];

    switch (header?.blockType) {
      case 'header-simple':
        return (
          <HeaderConnected
            {...header}
            locales={locales}
            jurisdictionList={jurisdictionList}
            jurisdictionItem={jurisdictionItem}
          />
        );
      default:
        return null;
    }
  }
  return null;
};

export default HeaderParser;
