import { cva, cx } from 'cva';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import React, { DetailedHTMLProps, FunctionComponent, ReactNode } from 'react';

import { EButtonSize, EButtonTheme, EButtonVariant } from './Button.constants';

type TButtonCommonProps = {
  /**
   * Content to render inside button tag.
   */
  children?: ReactNode;
  /**
   * Click callback.
   */
  onClick?: () => void;
  /**
   * Button type.
   */
  variant?: EButtonVariant;
  /**
   * Regulates button size using paddings.
   */
  size?: EButtonSize;
  /**
   * Color Theme.
   */
  theme?: EButtonTheme;
  /**
   * Adding rounding.
   */
  rounded?: boolean;
  disabled?: boolean;
  leadingIcon?: ImageProps;
  trailingIcon?: ImageProps;
  trailingIconComponent?: FunctionComponent;
};
export type TButtonProps = (
  | Omit<
      DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      'style'
    >
  | React.ComponentProps<typeof Link>
) &
  TButtonCommonProps;
export const buttonCVA = cva(
  'flex items-center justify-center text-center text-base font-medium leading-tight transition outline-none',
  {
    variants: {
      variant: {
        [EButtonVariant.Contained]: '',
        [EButtonVariant.Outlined]: '',
        [EButtonVariant.Text]: '',
      },
      theme: {
        [EButtonTheme.Primary]: '',
        [EButtonTheme.PrimaryOnColour]: '',
        [EButtonTheme.Secondary]: '',
        [EButtonTheme.SecondaryOnColour]: '',
        [EButtonTheme.Tertiary]: '',
      },
      size: {
        [EButtonSize.Small]: 'gap-2 py-2.5 px-4 text-sm',
        [EButtonSize.Medium]: 'gap-2.5 py-4 px-6',
        [EButtonSize.Large]: 'gap-2.5 py-5 px-10',
        [EButtonSize.Float]:
          'gap-2 py-5 px-5 text-md 2xl:py-2.5 2xl:px-4 2xl:text-sm',
      },
      rounded: {
        true: 'rounded-xl',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-40',
      },
    },
    compoundVariants: [
      {
        rounded: true,
        size: EButtonSize.Small,
        className: 'rounded-xl',
      },
      {
        variant: EButtonVariant.Contained,
        theme: EButtonTheme.Primary,
        className:
          'bg-brand-500 text-brand-50/90 hover:bg-brand-550 hover:text-brand-50',
      },
      {
        variant: EButtonVariant.Outlined,
        theme: EButtonTheme.Primary,
        className:
          'border border-brand-500 text-brand-500 hover:bg-brand-550 hover:text-brand-50',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.Primary,
        className: 'text-brand-500 hover:text-brand-550 !p-0',
      },
      {
        variant: EButtonVariant.Contained,
        theme: EButtonTheme.Secondary,
        className:
          'bg-control-100 text-control-950 hover:bg-control-150 hover:text-control-1000',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.Secondary,
        className: 'text-control-950 hover:text-control-1000',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.Tertiary,
        className: 'text-control-600 hover:text-control-650',
      },

      {
        variant: EButtonVariant.Contained,
        theme: EButtonTheme.PrimaryOnColour,
        className:
          'bg-brand-1000/80 text-brand-50/90 hover:bg-brand-1000 hover:text-brand-50',
      },
      {
        variant: EButtonVariant.Outlined,
        theme: EButtonTheme.PrimaryOnColour,
        className:
          'border border-brand-1000/80 text-brand-1000/80 hover:border-brand-1000 hover:bg-brand-1000 hover:text-brand-50/90',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.PrimaryOnColour,
        className: 'text-brand-1000/80 hover:text-brand-1000',
      },

      {
        variant: EButtonVariant.Contained,
        theme: EButtonTheme.SecondaryOnColour,
        className:
          'bg-brand-50/90 text-brand-1000/80 hover:bg-brand-50 hover:text-brand-1000',
      },
      {
        variant: EButtonVariant.Outlined,
        theme: EButtonTheme.SecondaryOnColour,
        className:
          'border border-brand-50/90 text-brand-50/90 hover:border-brand-50 hover:bg-brand-50 hover:text-brand-1000',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.SecondaryOnColour,
        className: 'text-brand-50/90 hover:text-brand-50',
      },
    ],
    defaultVariants: {
      theme: EButtonTheme.Primary,
      size: EButtonSize.Medium,
    },
  },
);

export const buttonIconCVA = cva('aspect-square', {
  variants: {
    size: {
      [EButtonSize.Small]: 'h-4 w-4',
      [EButtonSize.Medium]: '',
      [EButtonSize.Large]: '',
      [EButtonSize.Float]: '',
    },
  },
  defaultVariants: {
    size: EButtonSize.Medium,
  },
});

const Button = (props: TButtonProps): JSX.Element => {
  const {
    children,
    size = EButtonSize.Medium,
    variant = EButtonVariant.Contained,
    theme = EButtonTheme.Primary,
    rounded = true,
    leadingIcon,
    trailingIcon,
    className = '',
    trailingIconComponent: TrailingIconComponent,
    ...rest
  } = props;
  const { disabled } = rest;
  const contentButton = (
    <>
      {leadingIcon && (
        <Image
          {...leadingIcon}
          className={cx(buttonIconCVA({ size }))}
          aria-hidden={true}
        />
      )}
      {children}
      {TrailingIconComponent && <TrailingIconComponent />}
      {trailingIcon && (
        <Image
          {...trailingIcon}
          className={cx(buttonIconCVA({ size }))}
          aria-hidden={true}
        />
      )}
    </>
  );
  return 'href' in rest ? (
    <Link
      {...rest}
      className={cx(
        buttonCVA({ variant, theme, rounded, disabled, size }),
        className,
      )}
    >
      {contentButton}
    </Link>
  ) : (
    <button
      className={cx(
        buttonCVA({ variant, theme, rounded, disabled, size }),
        className,
      )}
      {...rest}
    >
      {contentButton}
    </button>
  );
};

export default Button;
