import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import React from 'react';

import { EInputVariant } from './Input.constants';

export interface IInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  variant?: EInputVariant;
  error?: string;
  prependIconName?: string;
  appendIconName?: string;
  fullWidth?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const {
    variant = EInputVariant.Primary,
    placeholder,
    required,
    id,
    error,
    fullWidth,
    ...restProps
  } = props;

  const [parent] = useAutoAnimate<HTMLDivElement>();
  const [wrapper] = useAutoAnimate<HTMLDivElement>();

  return (
    <div ref={parent} className='grid gap-2'>
      <div
        ref={wrapper}
        className={clsx(
          'grid h-15 gap-1 rounded-xl px-4',
          variant === EInputVariant.Primary && 'bg-surface-100-input',
          variant === EInputVariant.Secondary && 'bg-surface-50-input',
        )}
      >
        <input
          id={id}
          ref={ref}
          {...restProps}
          placeholder={`${placeholder}${required ? '*' : ''}`}
          required={required}
          className={clsx(
            'peer/input bg-transparent p-0 text-base font-medium leading-relaxed caret-brand-500 focus:outline-none',
            variant === EInputVariant.Primary && 'text-interface-800',
            fullWidth && 'w-full',
          )}
        />
      </div>
      {error && (
        <label className='px-4 text-xs leading-loose text-error'>{error}</label>
      )}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
