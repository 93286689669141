import { cx } from 'cva';
import React, { ReactNode, useEffect, useRef } from 'react';

import { EModalBackground } from '../Modal';
import Modal from '../Modal/Modal';

export interface IInternalModal {
  children: ReactNode;
  isOpenModal: boolean;
  onClose: () => void;
  modalTitle?: string;
  className?: string;
  modalBackground?: EModalBackground;
}

const InternalModal = ({
  children,
  modalTitle = '',
  isOpenModal,
  onClose,
  className,
  modalBackground,
}: IInternalModal): JSX.Element | null => {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (modalRef.current && isOpenModal) {
      modalRef.current.showModal();
    }
  }, [modalRef, isOpenModal]);

  if (!isOpenModal) {
    return null;
  }

  return (
    <dialog
      className={cx(
        'w-full max-w-[100vw] bg-transparent p-0 backdrop:bg-interface-1000/70 lg:max-w-md 2xl:px-4',
        className,
      )}
      onClose={onClose}
      ref={modalRef}
    >
      <Modal
        title={modalTitle}
        onClose={onClose}
        modalBackground={modalBackground}
      >
        {children}
      </Modal>
    </dialog>
  );
};

export default InternalModal;
