import { useGesture } from '@use-gesture/react';
import React, { useRef } from 'react';

type TUseDragScrollingResult = {
  scrollableRef: React.RefObject<HTMLElement>;
  bind: ReturnType<typeof useGesture>;
};

export const useDragScrolling = (): TUseDragScrollingResult => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollLeftValue = useRef(0);

  const bind = useGesture(
    {
      onDrag: ({ event, down, movement: [movx] }) => {
        event.preventDefault();

        if (down) {
          scrollableRef.current!.scrollLeft = scrollLeftValue.current - movx;
        }
      },
      onDragStart: ({ event }) => {
        event.preventDefault();
        scrollLeftValue.current = scrollableRef.current!.scrollLeft;
      },
    },
    {
      drag: {
        // it prevents starting youtube videos on drag
        filterTaps: true,
      },
    },
  );

  return { scrollableRef, bind };
};
