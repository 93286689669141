import React from 'react';

import Serialize from './Serialize';
import { TLeaf } from './types';
import updateNewTabInContentRichText from './utils/updateNewTabInContentRichText';

export type TRichTextContent = { [k: string]: unknown }[];

export type TRichTextProps = {
  content?: TLeaf | TLeaf[] | TRichTextContent;
  isOpenInNewTab?: boolean;
  className?: string;
};

const RichText: React.FC<TRichTextProps> = props => {
  const { content, isOpenInNewTab, className = '' } = props;

  const contentArray = !content
    ? []
    : (Array.isArray(content) ? content : [content]).filter(Boolean);

  let updatedContent: TLeaf[];
  if (isOpenInNewTab) {
    updatedContent = updateNewTabInContentRichText(contentArray as TLeaf[]);
  } else {
    updatedContent = contentArray as TLeaf[];
  }

  const isEmptyContent =
    updatedContent?.length === 1 &&
    updatedContent[0]?.children?.length === 1 &&
    updatedContent[0].children[0]?.text === '';

  if (!updatedContent.length || isEmptyContent) {
    return null;
  }

  return <div className={className}>{Serialize(updatedContent)}</div>;
};

export default RichText;
