import clsx from 'clsx';
import React from 'react';

import { Container } from '../../sections/Container';

export interface IConnectionSchemeProps {
  modules: { name: string }[];
  components: { name: string; features: { name: string }[] }[];
}

const Connector = (props: { className?: string }): JSX.Element => (
  <div
    className={clsx(
      props.className,
      'absolute z-10 h-6 w-[0.0625rem] -translate-x-1/2 border-interface-1000 bg-interface-1000 2xl:h-11 2xl:w-[0.125rem]',
      'before:absolute before:-top-[1px] before:left-1/2 before:z-20 before:block before:h-2 before:w-2 before:-translate-x-1/2 before:rounded-full before:border before:border-surface-50 before:bg-interface-1000 2xl:before:h-3.5 2xl:before:w-3.5',
      'after:absolute after:bottom-0 after:left-1/2 after:z-20 after:block after:h-2 after:w-2 after:-translate-x-1/2 after:rounded-full after:border after:border-surface-50 after:bg-interface-1000 2xl:after:h-3.5 2xl:after:w-3.5',
    )}
  />
);

const ConnectionScheme = (props: IConnectionSchemeProps): JSX.Element => {
  const { modules, components } = props;

  return (
    <Container>
      <section
        className={clsx(
          'grid gap-4 2xl:gap-7',
          modules.length === 0 && components.length === 0 && 'hidden',
        )}
      >
        <div className='flex flex-wrap justify-center gap-2 rounded-xl bg-surface-100 p-3 text-sm font-semibold leading-normal text-interface-1000 lg:p-4 2xl:p-8'>
          {modules.map((module, index) => (
            <span key={index} className='rounded-lg bg-surface-50 px-4 py-1'>
              {module.name}
            </span>
          ))}
        </div>
        <div className='grid grid-cols-2 gap-4 lg:grid-cols-3 lg:px-8 2xl:gap-7 2xl:px-14'>
          {components.map((component, index) => (
            <div
              key={index}
              className={clsx(
                'relative flex flex-col gap-3 rounded-xl bg-brand-1000 p-3 text-xs font-medium leading-normal text-control-100 lg:gap-4 lg:gap-4 lg:p-4 2xl:gap-7 2xl:p-7 2xl:text-lg',
                index > 1 &&
                  'col-start-1 col-end-3 lg:col-start-auto lg:col-end-auto',
              )}
            >
              {index === 0 && (
                <Connector className='-top-5 left-1/2 2xl:-top-9' />
              )}
              {index === 2 && (
                <Connector className='-top-5 left-1/2 hidden lg:block 2xl:-top-9' />
              )}
              {index === 1 && (
                <Connector className='-bottom-5 left-1/2 lg:-right-2 lg:bottom-auto lg:left-auto lg:top-6 lg:rotate-90 2xl:-right-4' />
              )}
              <div className='self-start rounded-lg bg-interface-50 px-2 py-1 text-xs leading-normal text-interface-1000 2xl:text-sm'>
                {component.name}
              </div>
              <ul
                className={clsx(
                  'flex gap-2 lg:gap-3.5 2xl:gap-6',
                  index > 1 && 'flex-row flex-wrap lg:flex-col',
                  index <= 1 && 'flex-col',
                )}
              >
                {component.features.map((feature, i) => (
                  <li key={i}>{feature.name}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </Container>
  );
};

export default ConnectionScheme;
