import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image, { ImageProps } from 'next/image';
import React from 'react';

import {
  ECardTextColor,
  EIconSize,
  ETitleSize,
  ETitleType,
} from './Card.constants';
import { Button } from '../Button';
import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../Eyebrow';
import { ETagSize, ETagTheme, ETagVariant, Tag } from '../Tag';

const AppStoreButton = dynamic<{ className?: string }>(() =>
  import(
    /* webpackChunkName: "AppStoreButton" */ '../../../../public/icons/button-app-store.svg'
  ).then(r => r.ReactComponent),
);

const GooglePlayButton = dynamic<{ className?: string }>(() =>
  import(
    /* webpackChunkName: "GooglePlayButton" */ '../../../../public/icons/button-google-play.svg'
  ).then(r => r.ReactComponent),
);

export interface ICardProps {
  /**
   * Icon to be displayed above the title.
   */
  icon?: ImageProps | null;
  iconSize?: EIconSize;

  /**
   * Tags to be displayed above the title.
   */
  tags?: React.ComponentProps<typeof Tag>[];

  /**
   * Choose tags style. With background or without.
   */
  tagsStyle?: ETagVariant;

  /**
   * Allows you to select a dark/light color type for the title, subTitle and description. Default "Dark".
   */
  cardTextColor?: ECardTextColor | null;

  /**
   * Type of title. Title could be a link.
   */
  titleType?: ETitleType;

  /**
   * Email for title link if titleType equal email.
   */
  email?: string;

  /**
   * Phone for title link if titleType equal phone.
   */
  phone?: string;

  /**
   * Font size for title. Default "Medium".
   */
  titleSize?: ETitleSize;

  /**
   * Short descriptive text consisting of 5-7 words or less.
   */
  title?: string;

  /**
   * Short text with brand color.
   */
  brandTitle?: string;

  /**
   * Short descriptive text, like a title, but smaller.
   */
  subTitle?: string;

  /**
   * Description of the card. There is no text limit for this field.
   */
  description?: JSX.Element | string | null;

  /**
   * Tags to be displayed under the dscription.
   */
  badges?: React.ComponentProps<typeof Tag>[];

  /**
   * Choose badges style. With background or without.
   */
  badgesStyle?: ETagVariant;

  badgesSize?: ETagSize;

  badgesTheme?: ETagTheme;

  /**
   * Buttons.
   */
  buttons?: React.ComponentProps<typeof Button>[];

  /**
   * GooglePlay and AppStore Buttons.
   */
  addons?: {
    downloads?: {
      appStoreUrl?: string;
      googlePlayUrl?: string;
    };
  };

  /**
   * Date. Used for news card.
   */
  cardDate?: string;

  /**
   * Puts an icon to the left of the content and change some styles for the "HorizontalCard" variant.
   */
  isHorizontalCard?: boolean;

  eyeBrowTitle?: string;
  eyeBrowTitleSize?: EEyebrowSize;
  className?: string;
}

/**
 * A fundamental basic component used as a building block for constructing more complex components.
 * Can be nested within a frame with paddings and a background color.
 * Can be stacked with images in a single container.
 *
 * @param props - Card props.
 * @returns JSX.Element.
 */
const Card = (props: ICardProps): JSX.Element => {
  const {
    icon,
    iconSize = EIconSize.XxxL,
    tags = [],
    tagsStyle = ETagVariant.Text,
    cardTextColor = ECardTextColor.Dark,
    titleType = ETitleType.Text,
    titleSize = ETitleSize.Medium,
    email,
    phone,
    title,
    brandTitle,
    subTitle,
    description,
    badges = [],
    eyeBrowTitleSize = EEyebrowSize.Small,
    badgesStyle = ETagVariant.Contained,
    badgesSize = ETagSize.Small,
    badgesTheme,
    buttons = [],
    addons,
    cardDate,
    isHorizontalCard,
    eyeBrowTitle,
    className,
  } = props;

  const isEmail = titleType === ETitleType.Email && email;

  const isPhone = titleType === ETitleType.Phone && phone;

  const href = isEmail
    ? `mailto:${email}`
    : isPhone
    ? `tel:${phone.replace(/[ )\-(]/g, '')}`
    : '';
  const disabledIconClasses = 'pointer-events-none opacity-40';
  const appStoreUrl = addons?.downloads?.appStoreUrl;
  const googlePlayUrl = addons?.downloads?.googlePlayUrl;

  const appStoreIconClasses = clsx('h-9', {
    [disabledIconClasses]: appStoreUrl === '#',
  });

  const googlePlayIconClasses = clsx('h-9', {
    [disabledIconClasses]: googlePlayUrl === '#',
  });

  const titleWrapper = (
    <h3
      className={clsx(
        'line-clamp-3 font-semibold leading-relaxed text-interface-1000',
        // titleSize
        titleSize === ETitleSize.Small && 'text-2xl',
        titleSize === ETitleSize.Medium && 'text-2xl 2xl:text-4xl',
        titleSize === ETitleSize.UnchangingMedium && 'text-4xl',
        titleSize === ETitleSize.Large &&
          'text-4xl 2xl:text-7xl 2xl:leading-snug',
        // cardTextColor
        cardTextColor === ECardTextColor.Dark && 'text-interface-1000',
        cardTextColor === ECardTextColor.Light && 'text-interface-50',
      )}
    >
      {title}
    </h3>
  );

  return (
    <div
      className={clsx(
        isHorizontalCard && 'flex flex-wrap items-start gap-4 2xl:flex-nowrap',
        className,
      )}
    >
      {icon && (
        <Image
          {...icon}
          className={clsx(
            'rounded-2xl object-contain',
            iconSize === EIconSize.XxxL && 'h-[4.5rem] w-[4.5rem]',
            iconSize === EIconSize.XxL && 'h-[3.5rem] w-[3.5rem]',
            iconSize === EIconSize.Xl && 'h-[2.25rem] w-[2.25rem]',
            iconSize === EIconSize.L && 'h-[1.5rem] w-[1.5rem]',
            !isHorizontalCard && 'mb-5',
          )}
        />
      )}
      {eyeBrowTitle && (
        <Eyebrow size={eyeBrowTitleSize} variant={EEyebrowVariant.Text}>
          {eyeBrowTitle}
        </Eyebrow>
      )}
      <div className='flex-auto 2xl:flex-1'>
        {tags && tags.length !== 0 && (
          <div className='mb-3 flex flex-wrap gap-3'>
            {tags.map((tag, index) => (
              <Tag
                theme={
                  tagsStyle === ETagVariant.Text
                    ? ETagTheme.Primary
                    : ETagTheme.Secondary
                }
                variant={tagsStyle}
                size={ETagSize.Small}
                key={index}
                {...tag}
              />
            ))}
          </div>
        )}
        {href && title ? (
          <a
            href={href}
            target='_blank'
            rel='noreferrer noopener'
            className={clsx(
              'prose-h3:transition',
              // hover color
              cardTextColor === ECardTextColor.Dark &&
                'hover:prose-h3:text-interface-1000/70',
              cardTextColor === ECardTextColor.Light &&
                'hover:prose-h3:text-interface-50/70',
            )}
          >
            {titleWrapper}
          </a>
        ) : (
          title && titleWrapper
        )}
        {brandTitle && (
          <div
            className={
              'mt-6 text-9xl font-semibold leading-tight text-brand-500'
            }
          >
            {brandTitle}
          </div>
        )}
        {subTitle && (
          <h4
            className={clsx(
              'mt-2 text-2xl font-semibold leading-relaxed text-interface-1000 first:mt-0',
              // cardTextColor
              cardTextColor === ECardTextColor.Dark && 'text-interface-1000',
              cardTextColor === ECardTextColor.Light && 'text-interface-50',
            )}
          >
            {subTitle}
          </h4>
        )}
        {description && (
          <div
            className={clsx(
              'mt-3 text-base leading-extra-loose transition empty:hidden prose-a:text-brand-500 prose-a:hover:text-brand-550',
              'prose-p:pb-3 last:prose-p:pb-0',
              // cardTextColor
              cardTextColor === ECardTextColor.Dark && 'text-interface-800',
              cardTextColor === ECardTextColor.Light && 'text-interface-50/70',
            )}
          >
            {description}
          </div>
        )}
        {badges && badges.length !== 0 && (
          <div className='mt-4 flex flex-row flex-wrap items-start gap-2'>
            {badges.map((badge, index) => (
              <Tag
                theme={
                  badgesTheme ||
                  (badgesStyle === ETagVariant.Text
                    ? ETagTheme.Primary
                    : ETagTheme.Secondary)
                }
                variant={badgesStyle}
                size={badgesSize || ETagSize.Small}
                key={index}
                {...badge}
              />
            ))}
          </div>
        )}
        {(appStoreUrl || googlePlayUrl) && (
          <div className='mt-7 flex gap-3 empty:hidden'>
            {appStoreUrl && (
              <a
                href={appStoreUrl}
                target='_blank'
                rel='noreferrer noopener'
                className={appStoreIconClasses}
              >
                <AppStoreButton className='h-full object-contain' />
              </a>
            )}
            {googlePlayUrl && (
              <a
                href={googlePlayUrl}
                target='_blank'
                rel='noreferrer noopener'
                className={googlePlayIconClasses}
              >
                <GooglePlayButton className='h-full object-contain' />
              </a>
            )}
          </div>
        )}
        {buttons && buttons.length !== 0 && (
          <div className='mt-7 flex flex-row flex-wrap items-start gap-2.5 lg:items-center'>
            {buttons.map((button, index) => (
              <Button key={index} {...button}>
                {button.children}
              </Button>
            ))}
          </div>
        )}
        {cardDate && (
          <div className='leading-4 mt-3 text-xs font-medium text-interface-800'>
            {cardDate}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
