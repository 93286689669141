import Link from 'next/link';
import React from 'react';

import Serialize from './Serialize';
import { TLeaf } from './types';
import buildNodeId from '../../../utils/buildNodeId';
import { Author } from '../Author';
import { Table } from '../Table';

export type TRelationshipField = {
  node: TLeaf;
  index: number;
};

const RelationshipField: React.FC<TRelationshipField> = props => {
  const { node, index } = props;

  const nodeId = node?.value?.title
    ? buildNodeId(node.value.title)
    : `nav${index}`;

  switch (node.relationTo) {
    case 'tables':
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment key={index}>
          {node.value?.items?.map(element => (
            <Table
              key={index}
              tableHead={element.tableHead}
              tableRows={element.tableRows}
            />
          ))}
        </React.Fragment>
      );
    case 'blockquotes':
      return (
        <blockquote
          className='relative mr-2 flex flex-col gap-3 overflow-y-hidden rounded-2xl bg-surface-100 p-6'
          key={index}
        >
          <div className='absolute -right-0 -top-0 h-full w-1 bg-brand-500' />
          {node.value?.eyebrow && (
            <div className='text-sm font-medium text-brand-500'>
              {node.value?.eyebrow}
            </div>
          )}
          <div className='max-w-full whitespace-pre-wrap font-semibold italic'>
            {Serialize(node?.value?.text)}
          </div>
          {node?.value?.author && <Author name={node?.value?.author} />}
          {node?.value?.isLinkRequired && node?.value?.link && (
            <Link
              className='text-xs font-normal !text-control-950'
              href={node.value.link.url}
            >
              {node.value.link.text}
            </Link>
          )}
        </blockquote>
      );
    case 'keyTakeaways':
      return (
        <div key={index} className='flex flex-col gap-3'>
          <span
            id={`${nodeId}-${index}`}
            className='invisible relative top-[-6rem]'
          />
          <h4>{node.value?.title}</h4>
          {node.value?.text && Serialize(node.value?.text)}
        </div>
      );
    case 'faqs-list':
      return (
        <div key={index} className='flex flex-col pb-2 pt-10'>
          <h6 className='mb-5 text-xl font-semibold uppercase text-brand-500'>
            {node.value?.title}
          </h6>
          <dl className='flex flex-col gap-5'>
            {node.value?.questions &&
              node.value?.questions?.map(item => {
                return (
                  <div className='flex flex-col pr-2'>
                    <dt className='text-base font-semibold text-control-1000'>
                      {item.question}
                    </dt>
                    <dd>{Serialize(item.answer)}</dd>
                  </div>
                );
              })}
          </dl>
        </div>
      );
    default:
      return <p key={index}>{Serialize(node.children)}</p>;
  }
};

export default RelationshipField;
