import { Header as THeader, Media } from '@shared/master-types';
import clsx from 'clsx';
import escapeHTML from 'escape-html';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import { Text } from 'slate';

import MultiLineText from './MultiLineText';
import RelationshipField from './RelationshipField';
import { TChildren } from './types';
import { skipEmptyNodesWithLink } from './utils/skipEmptyNodesWithLink';
import buildHashId from '../../../utils/buildHashId';
import buildStringFromRichTextRow from '../../../utils/buildStringFromRichTextRow';
import toImageProps from '../../../utils/toImageProps';
import toLinkParam from '../../../utils/toLinkParam';
import toLinkProps from '../../../utils/toLinkProps';
import { VideoPlayer } from '../VideoPlayer/index';

const Serialize = (children: TChildren = []): (JSX.Element | null)[] => {
  const hideClass = 'invisible relative top-[-6rem] -z-10';

  const router = useRouter();
  const linkParam = toLinkParam({
    locale: router.query.locale?.toString(),
    jurisdiction: router.query.jurisdiction?.toString(),
  });

  return children?.map((node, i) => {
    if (Text.isText(node)) {
      let text = <MultiLineText key={i} rowText={node.text} />;

      if (!node.text) {
        text = <br />;
      }

      if (node.bold) {
        text = <strong key={i}>{text}</strong>;
      }

      if (node.code) {
        text = <code key={i}>{text}</code>;
      }

      if (node.italic) {
        text = <em key={i}>{text}</em>;
      }

      if (node.underline) {
        text = (
          <span style={{ textDecoration: 'underline' }} key={i}>
            {text}
          </span>
        );
      }

      if (node.strikethrough) {
        text = (
          <span style={{ textDecoration: 'line-through' }} key={i}>
            {text}
          </span>
        );
      }

      return <Fragment key={i}>{text}</Fragment>;
    }

    if (!node) {
      return null;
    }

    const preparedString = buildStringFromRichTextRow(node.children);
    const nodeId = node.id || buildHashId(preparedString);

    switch (node.type) {
      case 'h1':
        return (
          <h1 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children)}
          </h1>
        );
      case 'h2':
        return (
          <h2 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children)}
          </h2>
        );
      case 'h3':
        return (
          <h3 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children)}
          </h3>
        );
      case 'h4':
        return (
          <h4 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children)}
          </h4>
        );
      case 'h5':
        return (
          <h5 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children)}
          </h5>
        );
      case 'h6':
        return (
          <h6 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children)}
          </h6>
        );
      case 'ul':
        return <ul key={i}>{Serialize(node.children)}</ul>;
      case 'ol':
        return <ol key={i}>{Serialize(node.children)}</ol>;
      case 'li':
        return (
          <li key={i}>
            {Serialize(skipEmptyNodesWithLink(node.children, true))}
          </li>
        );
      case 'link':
        return node.linkType === 'custom' ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={escapeHTML(node.url)}
            target={node.newTab ? '_blank' : '_self'}
            className='text-brand-500'
            rel={
              Array.isArray(node?.fields?.rel)
                ? node?.fields?.rel.join(' ')
                : ''
            }
            key={i}
          >
            {Serialize(node.children)}
          </a>
        ) : (
          <Link
            key={i}
            className='text-brand-500'
            {...toLinkProps(
              linkParam,
              node as unknown as NonNullable<
                NonNullable<
                  Extract<
                    Required<THeader>['header'][number],
                    { blockType: 'header-simple' }
                  >
                >['menuItems'][number]['action']
              >['link'],
            )}
          >
            {Serialize(node.children)}
          </Link>
        );
      case 'relationship':
        return <RelationshipField key={i} node={node} index={i} />;
      case 'upload':
        return node.value?.url ? (
          <Image
            className='!static'
            key={i}
            src=''
            alt=''
            {...toImageProps(node.value as Media, { useFill: true })}
          />
        ) : null;
      case 'video':
        return node.id ? (
          <div key={i}>
            <VideoPlayer embedId={node.id as string} />
          </div>
        ) : null;

      default:
        return (
          <p key={i}>{Serialize(skipEmptyNodesWithLink(node.children))}</p>
        );
    }
  });
};

export default Serialize;
