export const enum EEyebrowSize {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export const enum EEyebrowVariant {
  Contained = 'contained',
  Outlined = 'outlined',
  Text = 'text',
}

export const enum EEyebrowTheme {
  Primary = 'primary',
  Light = 'light',
  Dark = 'dark',
}
