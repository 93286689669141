import { Button, RichText } from '@front/shared/ds';
import React, { useEffect, useState } from 'react';

import { useGetSettingsQuery } from '../../../features';
import { isSSR } from '../../../utils';

export const CookieBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { data: settings } = useGetSettingsQuery();

  const cookieBannerEnabled =
    !isSSR() &&
    settings?.cookieBanner &&
    !localStorage.getItem(settings?.cookieBanner.buttonText) &&
    settings?.cookieBanner.bodyText &&
    settings?.cookieBanner.buttonText &&
    settings?.cookieBanner.shouldShow;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!showBanner && cookieBannerEnabled) {
      timeout = setTimeout(() => {
        setShowBanner(true);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieBannerEnabled]);

  if (!cookieBannerEnabled || !showBanner) {
    return null;
  }

  const { cookieBanner } = settings;

  const onClose = (): void => {
    setShowBanner(false);
    localStorage.setItem(cookieBanner.buttonText, JSON.stringify(true));
  };

  return (
    <div
      className='fixed bottom-4 left-1/2 z-50 flex w-full max-w-[22.438rem] -translate-x-1/2 flex-col items-center gap-4 rounded-xl bg-surface-50-tooltip/30 p-4 text-center text-sm leading-loose text-interface-1000 backdrop-blur-md md:bottom-6 md:max-w-[33.75rem] md:flex-row md:justify-between md:p-4 md:px-6 md:py-7 md:text-left'
      role='dialog'
    >
      <RichText content={cookieBanner.bodyText} />
      <Button
        className='w-full md:w-auto md:whitespace-nowrap'
        onClick={onClose}
      >
        {cookieBanner.buttonText}
      </Button>
    </div>
  );
};
