import { baseTailwindConfig } from '@front/shared';
import { toImageProps } from '@front/shared/ds';
import { Hero, Media } from '@shared/master-types';
import clsx from 'clsx';
import Image from 'next/image';
import React, { ReactNode } from 'react';
import { useMedia } from 'react-use';

type TUseHeroImagesProps = {
  hero: Hero;
  customImages?: ReactNode[];
};

type TUseHeroImagesResult = {
  images: ReactNode[];
  paddingTop: number;
};

export const useHeroImages = ({
  customImages,
  hero,
}: TUseHeroImagesProps): TUseHeroImagesResult => {
  const {
    variant,
    imageLeftDesktop,
    imageRightDesktop,
    imageMobile,
    coverImage,
  } = hero;

  const isMatchesMobileImageBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
    true,
  );

  let paddingTop = 0;
  let threeImagesBreakpoint = 0;

  if (variant === 'ThreeImages') {
    threeImagesBreakpoint =
      Number((imageLeftDesktop as Media)?.width || 0) +
      Number((imageRightDesktop as Media)?.width || 0);

    paddingTop = isMatchesMobileImageBreakpoint
      ? 0
      : ((imageMobile as Media)?.height || 0) + 16;
  }

  const isMatchesBreakpoint = useMedia(
    `(min-width: ${threeImagesBreakpoint}px)`,
    true,
  );

  let images = customImages || [];
  if (variant === 'ThreeImages') {
    images = [];
    const mobileImageProps = toImageProps(imageMobile);
    const leftImageProps = toImageProps(imageLeftDesktop);
    const rightImageProps = toImageProps(imageRightDesktop);

    if (mobileImageProps) {
      images.push(
        <Image
          key='mobile'
          className='absolute left-1/2 top-0 max-w-none -translate-x-1/2 xl:hidden'
          priority
          {...mobileImageProps}
          alt={mobileImageProps.alt}
          src={mobileImageProps.src}
        />,
      );
    }

    if (leftImageProps) {
      images.push(
        <Image
          key='left'
          className={clsx(
            'absolute top-0 hidden xl:block',
            isMatchesBreakpoint ? 'left-0' : 'right-[50%]',
          )}
          priority
          {...leftImageProps}
          alt={leftImageProps.alt}
          src={leftImageProps.src}
        />,
      );
    }

    if (rightImageProps) {
      images.push(
        <Image
          key='right'
          className={clsx(
            'absolute top-0 hidden xl:block',
            isMatchesBreakpoint ? 'right-0' : 'left-[50%]',
          )}
          priority
          {...rightImageProps}
          alt={rightImageProps.alt}
          src={rightImageProps.src}
        />,
      );
    }
  }
  if (variant === 'CoverImage') {
    const coverImageProps = toImageProps(coverImage);
    if (coverImageProps) {
      images.push(
        <Image
          key='Cover Image'
          className='absolute left-0 top-0 h-full w-full object-cover object-center'
          priority
          fill={true}
          alt={coverImageProps.alt}
          src={coverImageProps.src}
        />,
      );
    }
  }

  return {
    images,
    paddingTop,
  };
};
