export const enum EBannerCardLayout {
  BannerCard = 'BannerCard',
  BannerCardWithBg = 'BannerCardWithBg',
}

export const enum EBannerCardBackground {
  White = 'White',
  Light = 'Light',
  Dark = 'Dark',
  Brand = 'Brand',
}
