import Image, { ImageProps } from 'next/image';
import React from 'react';

export interface ILogoCloudProps {
  items: {
    logo: ImageProps | null;
  }[];
}

const LogoCloud = (props: ILogoCloudProps): JSX.Element => {
  const { items } = props;

  return (
    <section className='flex flex-wrap justify-center gap-3 2xl:gap-8'>
      {items.map(({ logo }, index) =>
        logo ? (
          <Image
            key={index}
            {...logo}
            className='h-10 w-29 shrink-0 object-contain object-center lg:h-14 lg:w-40 2xl:h-16 2xl:w-46'
          />
        ) : null,
      )}
    </section>
  );
};

export default LogoCloud;
