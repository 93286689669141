import clsx from 'clsx';
import React from 'react';

export type TStepBarProps = {
  steps: { label: string; value: string }[];
  currentStepIdx: number;
  onChange?: (index: number) => void;
};

const StepBar = (props: TStepBarProps): JSX.Element => {
  const { steps, currentStepIdx, onChange } = props;

  return (
    <div className='flex gap-2'>
      {steps.map((step, idx) => (
        <div
          key={step.value}
          className='w-full cursor-pointer'
          onClick={() => onChange?.(idx)}
        >
          {/* label */}
          <div
            className={clsx(
              'mb-3 text-center text-sm leading-relaxed',
              // hide label if it's not the current step
              idx !== currentStepIdx && 'opacity-0',
            )}
          >
            {step.label}
          </div>
          {/* line */}
          <div
            className={clsx(
              'h-1.5 w-full rounded-md',
              // highlight line if it's the current step
              idx === currentStepIdx ? 'bg-brand-500' : 'bg-surface-200',
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default StepBar;
