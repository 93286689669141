import {
  EButtonSize,
  EButtonVariant,
  TBannerCardProps,
  toImageProps,
  toLinkProps,
} from '@front/shared/ds';

import { TBannerCardsConnectedProps } from '../components/blocks/BannerCardsConnected/BannerCardsConnected';
import { setCurrentModalId } from '../features/modals/modals.state';
import { TAppDispatch } from '../store/store.types';

export default function toCardButtons(
  buttons: NonNullable<TBannerCardsConnectedProps['cards']>[number]['buttons'],
  locale: string,
  dispatch: TAppDispatch,
): TBannerCardProps['buttons'] {
  return (buttons || []).map(button => {
    if (button.type === 'button') {
      return {
        children: button.button?.text,
        trailingIcon: toImageProps(button?.icon) || undefined,
        onClick: () => {
          if (
            button.button?.action === 'modal' &&
            button.button?.modal?.reference.value
          ) {
            return dispatch(
              setCurrentModalId({
                modalId: String(button.button?.modal?.reference.value),
              }),
            );
          }
          return undefined;
        },
        size: EButtonSize.Small,
        variant:
          (button?.variant as EButtonVariant) || EButtonVariant.Contained,
      };
    } else {
      return {
        ...toLinkProps(String(locale), button.link),
        children: button?.link?.text,
        size: EButtonSize.Small,
        variant: (button?.variant as EButtonVariant) || EButtonVariant.Text,
        onClick: undefined,
        trailingIcon: toImageProps(button?.icon) || undefined,
      };
    }
  });
}
