import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
} from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import * as querystring from 'qs';

import { TAppDispatch, TAppStartListening, TRootState } from './store.types';
import { ADMIN_API_MARKER } from '../consts/ADMIN_API_MARKER';
import { isSSR } from '../utils';

const baseQuery = fetchBaseQuery({
  baseUrl: isSSR() ? `${process.env.NEXT_ADMIN_HOST}/api` : ADMIN_API_MARKER,
  paramsSerializer: params => {
    const nextParams = { ...params };
    // TODO: migrate translations to remove this workaround (https://b2btech.atlassian.net/browse/WEB-14244)
    const isChineseNewPrefix = nextParams.locale === 'zh-hans';

    if (isChineseNewPrefix) {
      nextParams.locale = 'zh';
    }
    return querystring.stringify(nextParams);
  },
  credentials: 'include',
  prepareHeaders: (headers, { extra }) => {
    const { cookies } = extra as { cookies: Record<string, string> };
    if (cookies) {
      headers.set('cookie', `payload-token=${cookies['payload-token']}`);
    }

    return headers;
  },
});

export const baseApi = createApi({
  baseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
  tagTypes: [],
});

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening =
  listenerMiddleware.startListening as TAppStartListening;

export const addAppListener = addListener as TypedAddListener<
  TRootState,
  TAppDispatch
>;
