import { baseApi } from '../../store/store.constants';

import type { Header } from '@shared/master-types';
import type { PaginatedDocs } from 'payload/dist/mongoose/types';

const layoutApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getHeader: build.query<PaginatedDocs<Header>, { locale?: string } | void>({
      query: params => ({
        url: '/header/?depth=1',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getHeader },
  useGetHeaderQuery,
} = layoutApi;
