import { ETagSlug } from '../components/atoms/Tag';

export default function buildTagCategoryLink(
  currentLocale: string,
  jurisdiction?: string,
  tagSlug?: string,
): string {
  let categoryLink = '';

  if (currentLocale !== 'en') {
    categoryLink += `/${currentLocale}`;
  }

  if (jurisdiction) {
    categoryLink += `/${jurisdiction}`;
  }

  if (tagSlug) {
    categoryLink += `/${tagSlug === ETagSlug.All ? ETagSlug.News : tagSlug}`;
  }

  return categoryLink;
}
