import React from 'react';

import {
  CardWrapper,
  ECardWrapperLayout,
  ICardWrapperProps,
} from '../../atoms/CardWrapper';

interface IBrandCardProps extends ICardWrapperProps {
  size?: ECardWrapperLayout.BrandCard;
}

export type TBrandCardProps = Pick<
  IBrandCardProps,
  | 'icon'
  | 'size'
  | 'title'
  | 'brandTitle'
  | 'subTitle'
  | 'description'
  | 'image'
  | 'tags'
  | 'tagsStyle'
  | 'badges'
  | 'badgesStyle'
  | 'buttons'
>;

const BrandCard = (props: TBrandCardProps): JSX.Element => {
  const { size = ECardWrapperLayout.BrandCard, ...rest } = props;

  return <CardWrapper size={size} {...rest} />;
};

export default BrandCard;
