import { IInputProps } from './Input';

export const enum EInputVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export const enum EInputField {
  Name = 'name',
  Email = 'email',
}

export const INPUT_CONFIGS: Record<EInputField, IInputProps> = {
  [EInputField.Name]: {
    name: 'name',
    type: 'text',
    autoComplete: 'name',
    placeholder: 'Name',
  },
  [EInputField.Email]: {
    name: 'email',
    type: 'text',
    autoComplete: 'email',
    placeholder: 'Email',
  },
} as const;
