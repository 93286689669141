import React, { useState, useEffect, useCallback } from 'react';

const ScrollIndicator = (): JSX.Element => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = useCallback(() => {
    const maxScrollY =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollY = window.scrollY;
    const percentage = (scrollY / maxScrollY) * 100;
    setScrollPercentage(percentage);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className='absolute bottom-0 left-0 right-0 top-0 -z-10 overflow-hidden rounded-xl 2xl:rounded-3xl'>
      <div
        className='absolute bottom-0 left-0 h-[0.188rem] rounded-full bg-brand-500/90 2xl:h-1'
        style={{ width: `${scrollPercentage}%` }}
      />
    </div>
  );
};

export default ScrollIndicator;
