import { getGaId } from '@front/shared/utils';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { TAction } from '../../../types';
import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '../../atoms/Button';
import { Tag, ETagSize, ETagTheme, ETagVariant } from '../../atoms/Tag';

export interface IFooterWithSitemapProps {
  callToAction?: {
    title?: string;
    subtitle?: string;
    actions?: TAction[];
  };
  menu: {
    label: string;
    links: TAction[];
  }[];
  description?: JSX.Element | null | string;
  contacts?: {
    label: string;
    value: string;
    type: 'email' | 'telephone' | 'text';
  }[];
}

const FooterWithSitemap = (props: IFooterWithSitemapProps): JSX.Element => {
  const { callToAction, description, menu, contacts = [] } = props;

  return (
    <footer className='pt-12 2xl:pt-16'>
      <div className='rounded-[1.75rem_1.75rem_0_0] bg-surface-100 px-4 py-12 2xl:px-24 2xl:py-16'>
        {callToAction && (
          <section
            className='flex flex-col items-center text-center'
            data-ga-id={getGaId(
              callToAction.title || callToAction.subtitle,
              'footer',
            )}
          >
            {callToAction.subtitle && (
              <Tag
                variant={ETagVariant.Text}
                size={ETagSize.Small}
                theme={ETagTheme.Primary}
              >
                {callToAction.subtitle}
              </Tag>
            )}
            {callToAction.title && (
              <h2 className='mt-3 w-full max-w-[21.438rem] whitespace-pre-line text-4xl font-semibold leading-relaxed text-interface-1000 lg:leading-normal xl:max-w-[38.75rem] xl:text-7xl xl:leading-snug'>
                {callToAction.title}
              </h2>
            )}
            {callToAction.actions && (
              <div className='mt-6 w-full self-center xl:w-auto'>
                {callToAction.actions.map((action, index) =>
                  action.type === 'button' ? (
                    <React.Fragment key={index}>
                      <div className='hidden xl:block'>
                        <Button
                          {...action.props}
                          size={EButtonSize.Medium}
                          variant={EButtonVariant.Contained}
                          theme={EButtonTheme.Primary}
                          data-ga-id={getGaId(action?.text, 'footer')}
                        />
                      </div>
                      <div className='grid w-full xl:hidden'>
                        <Button
                          {...action.props}
                          size={EButtonSize.Large}
                          variant={EButtonVariant.Contained}
                          theme={EButtonTheme.Primary}
                          data-ga-id={getGaId(action?.text, 'footer')}
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    action.type === 'link' && (
                      <Link
                        key={index}
                        {...action.props}
                        className='block rounded px-3 py-2 text-sm font-medium leading-normal text-control-950 transition hover:bg-control-100 aria-disabled:cursor-not-allowed aria-disabled:opacity-40'
                        data-ga-id={getGaId(action?.text, 'footer')}
                      />
                    )
                  ),
                )}
              </div>
            )}
          </section>
        )}
        {menu && (
          <section className='mt-12 grid grid-cols-2 flex-wrap justify-start gap-x-12 gap-y-8 border-t border-t-surface-200 pt-12 lg:grid-cols-3 lg:justify-center lg:gap-x-16 2xl:mt-16 2xl:flex 2xl:grid-cols-8 2xl:gap-16 2xl:pt-16 '>
            {menu?.map((menuItem, menuColIndex) => (
              <div className='2xl:w-auto' key={menuColIndex}>
                <h3 className='mb-4 text-lg font-semibold leading-normal text-black '>
                  {menuItem.label}
                </h3>
                <div className='flex flex-col gap-3.5'>
                  {menuItem.links.map((link, index) => (
                    <li key={index} className='list-none'>
                      {link.type === 'link' && (
                        <Link
                          key={index}
                          {...link.props}
                          className='inline-block text-sm font-medium leading-normal text-control-600 transition hover:text-control-650 aria-disabled:cursor-not-allowed aria-disabled:opacity-40'
                          data-ga-id={getGaId(
                            link?.text,
                            'footer',
                            menuItem.label,
                          )}
                        />
                      )}
                    </li>
                  ))}
                </div>
              </div>
            ))}
          </section>
        )}
        {contacts.length !== 0 && (
          <section className='relative mt-12 rounded-2xl bg-surface-50 p-6 2xl:mt-16 2xl:p-9'>
            <div className='flex flex-wrap gap-5 2xl:justify-center 2xl:gap-10'>
              {contacts.map(contact => (
                <div
                  key={contact.value}
                  className={clsx(
                    'grid gap-1',
                    contact.type === 'email' && '2xl:order-1',
                  )}
                >
                  <span className='text-xs leading-loose text-interface-500'>
                    {contact.label}
                  </span>
                  {contact && contact.type === 'email' ? (
                    <a
                      href={`mailto:${contact.value}`}
                      target='_blank'
                      rel='noreferrer noopener'
                      className='text-sm leading-loose text-control-950'
                    >
                      {contact.value}
                    </a>
                  ) : contact && contact.type === 'telephone' ? (
                    <a
                      href={`tel:${contact.value.replace(/ /g, '')}`}
                      target='_blank'
                      rel='noreferrer noopener'
                      className='text-sm leading-loose text-control-950'
                    >
                      {contact.value}
                    </a>
                  ) : contact && contact.type === 'text' ? (
                    <span className='text-sm leading-loose text-control-950'>
                      {contact.value}
                    </span>
                  ) : null}
                </div>
              ))}
            </div>
          </section>
        )}

        <div className='pt-12 2xl:pt-16'>
          <div className='text-left text-sm leading-loose text-interface-500 empty:hidden prose-a:text-interface-500 prose-a:underline prose-a:transition hover:prose-a:text-brand-500'>
            {description}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterWithSitemap;
