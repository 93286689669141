import { cva, cx } from 'cva';
import React, { ReactNode } from 'react';

import {
  EEyebrowSize,
  EEyebrowVariant,
  EEyebrowTheme,
} from './Eyebrow.constants';

export const eyebrowCVA = cva(
  'font-medium inline-flex justify-center items-center',
  {
    variants: {
      variant: {
        [EEyebrowVariant.Contained]: '',
        [EEyebrowVariant.Outlined]: 'border border-solid',
        [EEyebrowVariant.Text]: '',
      },
      size: {
        [EEyebrowSize.Large]: 'text-lg leading-normal rounded-xl',
        [EEyebrowSize.Medium]: 'text-base leading-relaxed rounded-lg',
        [EEyebrowSize.Small]: 'text-sm leading-normal rounded-lg',
        [EEyebrowSize.ExtraSmall]: 'text-xs leading-relaxed rounded-lg',
      },
      theme: {
        [EEyebrowTheme.Primary]: '',
        [EEyebrowTheme.Light]: '',
        [EEyebrowTheme.Dark]: '',
      },
    },
    compoundVariants: [
      {
        variant: [EEyebrowVariant.Contained, EEyebrowVariant.Outlined],
        size: [EEyebrowSize.Large],
        className: 'py-[5px] px-3 h-[32px]',
      },
      {
        variant: [EEyebrowVariant.Contained, EEyebrowVariant.Outlined],
        size: [EEyebrowSize.Medium],
        className: 'py-[4.5px] px-3 h-[28px]',
      },
      {
        variant: [EEyebrowVariant.Contained, EEyebrowVariant.Outlined],
        size: [EEyebrowSize.Small],
        className: 'py-[4.5px] px-3 h-[26px]',
      },
      {
        variant: [EEyebrowVariant.Contained, EEyebrowVariant.Outlined],
        size: [EEyebrowSize.ExtraSmall],
        className: 'py-[4.5px] px-3 h-[24px]',
      },
      {
        variant: [EEyebrowVariant.Contained],
        theme: [EEyebrowTheme.Primary],
        className: 'bg-brand-500 text-brand-50',
      },
      {
        variant: [EEyebrowVariant.Outlined, EEyebrowVariant.Text],
        theme: [EEyebrowTheme.Primary],
        className: 'text-brand-500 border-brand-500',
      },
      {
        variant: [EEyebrowVariant.Contained],
        theme: [EEyebrowTheme.Light],
        className: 'bg-brand-50 text-brand-500',
      },
      {
        variant: [EEyebrowVariant.Outlined, EEyebrowVariant.Text],
        theme: [EEyebrowTheme.Light],
        className: 'text-brand-50 border-brand-50',
      },
      {
        variant: [EEyebrowVariant.Contained],
        theme: [EEyebrowTheme.Dark],
        className: 'bg-brand-1000 text-brand-50',
      },
      {
        variant: [EEyebrowVariant.Outlined, EEyebrowVariant.Text],
        theme: [EEyebrowTheme.Dark],
        className: 'text-brand-1000 border-brand-1000',
      },
    ],
  },
);

type TEyebrowProps = {
  children: ReactNode;
  size?: EEyebrowSize;
  variant?: EEyebrowVariant;
  theme?: EEyebrowTheme;
};

export const Eyebrow: React.FC<TEyebrowProps> = ({
  children,
  size = EEyebrowSize.Large,
  variant = EEyebrowVariant.Contained,
  theme = EEyebrowTheme.Primary,
}) => {
  return (
    <span className={cx(eyebrowCVA({ variant, size, theme }))}>{children}</span>
  );
};
