import React from 'react';

import Accordion from '../../atoms/Accordion/Accordion';
import { Container } from '../../sections/Container';

export interface IAccordionsGridProps {
  accordions: (React.ComponentProps<typeof Accordion> & { id?: string })[];
}

const AccordionsGrid = (props: IAccordionsGridProps): JSX.Element => {
  const { accordions } = props;

  return (
    <Container>
      <div className='grid gap-4 2xl:grid-cols-12'>
        {accordions.map(({ id, ...rest }, index) => (
          <div key={id ?? index} className='2xl:col-span-8 2xl:col-start-3'>
            <Accordion {...rest} />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default AccordionsGrid;
