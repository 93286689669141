import { ContentSection } from '@front/shared/ds';
import { getGaId } from '@front/shared/utils';
import { Jurisdiction, Locale, Section } from '@shared/master-types';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';

import { ErrorBoundary } from '../../blocks/ErrorBoundary';

const AccordionConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "AccordionsGridConnected" */ '../../blocks/AccordionsGridConnected/AccordionsGridConnected'
    ),
);
const StatsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "StatsConnected" */ '../../blocks/StatsConnected'
    ),
);
const CountriesRowConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CountriesRowConnected" */ '../../blocks/CountriesRowConnected'
    ),
);
const ContactsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "ContactsConnected" */ '../../blocks/ContactsConnected'
    ),
);
const ConnectionSchemeConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "ConnectionSchemeConnected" */ '../../blocks/ConnectionSchemeConnected'
    ),
);
const FeaturesGridConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FeaturesGridConnected" */ '../../blocks/FeaturesGridConnected'
    ),
);
const TabbedCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TabbedCardsConnected" */ '../../blocks/TabbedCardsConnected'
    ),
);
const LogoCloudConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "LogoCloudConnected" */ '../../blocks/LogoCloudConnected'
    ),
);
const HorizontalCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "HorizontalCardsConnected" */ '../../blocks/HorizontalCardsConnected'
    ),
);
const BannerCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BannerCardsConnected" */ '../../blocks/BannerCardsConnected'
    ),
);
const DefaultCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BannerCardsConnected" */ '../../blocks/DefaultCardsConnected'
    ),
);
const BrandCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BannerCardsConnected" */ '../../blocks/BrandCardsConnected'
    ),
);
const BannerListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BannerListConnected" */ '../../blocks/BannerListConnected'
    ),
);

const FormSlotConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FormSlotConnected" */ '../../blocks/FormSlotConnected'
    ),
);

const CurrencyListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CurrencyListConnected" */ '../../blocks/CurrencyListConnected'
    ),
);

const DocumentsListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "DocumentsListConnected" */ '../../blocks/DocumentsListConnected'
    ),
);

const RecentArticlesConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "RecentArticlesConnected" */ '../../blocks/RecentArticlesConnected'
    ),
);
const NewsBlockConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "NewsBlockConnected" */ '../../blocks/NewsBlockConnected'
    ),
);
const EventsBlockConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "NewsBlockConnected" */ '../../blocks/EventsBlockConnected'
    ),
);
const VideosBlockConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "VideosConnected" */ '../../blocks/VideosBlockConnected'
    ),
);

const VideoPlayerListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "VideosConnected" */ '../../blocks/VideoPlayerListConnected'
    ),
);

const FinanceTableConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FinanceTableConnected" */ '../../blocks/FinanceTableConnected'
    ),
);

const PricingPlanConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "PricingPlanConnected" */ '../../blocks/PricingPlanConnected'
    ),
);

const ChartScrollableConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "ChartScrollableConnected" */ '../../blocks/ChartScrollableConnected'
    ),
);

const VerticalCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "VerticalCardsConnected" */ '../../blocks/VerticalCardsConnected'
    ),
);

const QuoteCardConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "QuoteCardConnected" */ '../../blocks/QuoteCardConnected'
    ),
);

const TreeDiagramConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TreeDiagramConnected" */ '../../blocks/TreeDiagramConnected'
    ),
);

const BannerCardWithTitleCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TreeDiagramConnected" */ '../../blocks/BannerCardWithTitleCardsConnected'
    ),
);

const PlatesDefaultCardConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TreeDiagramConnected" */ '../../blocks/PlatesDefaultCardConnected'
    ),
);

export interface ILayoutParserProps {
  section: Section;
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
  customSectionRowRenderer?: (row: any) => React.ReactNode; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const SectionParser = (props: ILayoutParserProps): JSX.Element | null => {
  const {
    section,
    locales,
    jurisdictionList,
    jurisdictionItem,
    customSectionRowRenderer,
  } = props;

  const {
    rows = [],
    name,
    title,
    slug,
    description,
    bottomDescription,
    isSubtitleTop,
    subtitle,
  } = section;

  const style = section.style as Omit<
    React.ComponentProps<typeof ContentSection>,
    | 'title'
    | 'children'
    | 'background'
    | 'isRounded'
    | 'containerVariant'
    | 'containerBackground'
    | 'isContainerRounded'
  >;

  const backgroundStyle = section.backgroundStyle as Omit<
    React.ComponentProps<typeof ContentSection>,
    | 'children'
    | 'title'
    | 'subtitle'
    | 'isSubtitleTop'
    | 'spacingY'
    | 'titleSpacingY'
    | 'rowSpacingY'
    | 'description'
    | 'bottomDescription'
  >;
  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const handleEmptyContent = (): void => {
    if (rows.length === 1) {
      setIsEmptyContent(true);
    }
  };

  if (isEmptyContent) {
    return null;
  }

  return (
    <ErrorBoundary>
      <ContentSection
        {...style}
        {...backgroundStyle}
        title={title}
        id={slug}
        description={description}
        bottomDescription={bottomDescription}
        isSubtitleTop={isSubtitleTop}
        subtitle={subtitle}
        dataGaId={getGaId(title || name, 'section')}
      >
        {rows.map(row => {
          if (customSectionRowRenderer) {
            const result = customSectionRowRenderer(row);

            if (result !== undefined) {
              return result;
            }
          }

          switch (row.blockType) {
            case 'faq':
              return <AccordionConnected key={row.id} {...row} />;
            case 'form-slot':
              return <FormSlotConnected key={row.id} {...row} />;
            case 'logo-cloud':
              return <LogoCloudConnected key={row.id} {...row} />;
            case 'stats':
              return <StatsConnected key={row.id} {...row} />;
            case 'contacts':
              return <ContactsConnected key={row.id} {...row} />;
            case 'connection-scheme':
              return <ConnectionSchemeConnected key={row.id} {...row} />;
            case 'pricing':
              return (
                <PricingPlanConnected
                  locales={locales}
                  jurisdictionList={jurisdictionList}
                  jurisdictionItem={jurisdictionItem}
                  key={row.id}
                  {...row}
                />
              );
            case 'feature-grids':
              return <FeaturesGridConnected key={row.id} {...row} />;
            case 'tabbed-cards':
              return <TabbedCardsConnected key={row.id} {...row} />;
            case 'banner-cards':
              return <BannerCardsConnected key={row.id} {...row} />;
            case 'horizontal-cards':
              return <HorizontalCardsConnected key={row.id} {...row} />;
            case 'default-cards':
              return <DefaultCardsConnected key={row.id} {...row} />;
            case 'brand-cards':
              return <BrandCardsConnected key={row.id} {...row} />;
            case 'banner-list':
              return <BannerListConnected key={row.id} {...row} />;
            case 'banner-card-w-title-cards':
              return (
                <BannerCardWithTitleCardsConnected key={row.id} {...row} />
              );
            case 'recent-articles':
              return (
                <RecentArticlesConnected
                  locales={locales}
                  jurisdictionList={jurisdictionList}
                  jurisdictionItem={jurisdictionItem}
                  key={row.id}
                  {...row}
                />
              );
            case 'news-block':
              return (
                <NewsBlockConnected
                  locales={locales}
                  jurisdictionList={jurisdictionList}
                  jurisdictionItem={jurisdictionItem}
                  key={row.id}
                  {...row}
                />
              );
            case 'events-block':
              return (
                <EventsBlockConnected
                  locales={locales}
                  jurisdictionList={jurisdictionList}
                  jurisdictionItem={jurisdictionItem}
                  key={row.id}
                  handleEmptyContent={handleEmptyContent}
                  {...row}
                />
              );
            case 'videos-block':
              return (
                <VideosBlockConnected
                  locales={locales}
                  jurisdictionList={jurisdictionList}
                  jurisdictionItem={jurisdictionItem}
                  key={row.id}
                  {...row}
                />
              );
            case 'video-player-list':
              return <VideoPlayerListConnected key={row.id} {...row} />;
            case 'documents-list':
              return <DocumentsListConnected key={row.id} {...row} />;
            case 'countries-row':
              return <CountriesRowConnected key={row.id} {...row} />;
            case 'currency-list':
              return <CurrencyListConnected key={row.id} {...row} />;
            case 'finance-table':
              return <FinanceTableConnected key={row.id} {...row} />;
            case 'chart-scrollable':
              return <ChartScrollableConnected key={row.id} {...row} />;
            case 'tree-diagram':
              return <TreeDiagramConnected key={row.id} {...row} />;
            case 'vertical-cards':
              return <VerticalCardsConnected key={row.id} {...row} />;
            case 'quote-card':
              return <QuoteCardConnected key={row.id} {...row} />;
            case 'plates-default-card':
              return <PlatesDefaultCardConnected key={row.id} {...row} />;
            default:
              return null;
          }
        })}
      </ContentSection>
    </ErrorBoundary>
  );
};

export default SectionParser;
