import clsx from 'clsx';
import React, { ReactNode } from 'react';

import {
  ERowSpacing,
  ESectionBackground,
  ESectionSpacing,
  ETextColor,
  ETitleSpacing,
  ETitleTextAlign,
} from './ContentSection.constants';
import {
  EEyebrowSize,
  EEyebrowTheme,
  EEyebrowVariant,
  Eyebrow,
} from '../../atoms/Eyebrow';
import { RichText } from '../../atoms/RichText';
import {
  Container,
  EContainerBackground,
  EContainerMobileRadius,
  EContainerVariant,
} from '../Container';

export interface IContentSectionProps {
  index?: number;
  /**
   * The section content. Each child will be placed on a new row.
   */
  children: ReactNode;
  /**
   * The section title.
   */
  title?: string;
  /**
   * The section subtitle.
   */
  subtitle?: string;
  /**
   * Move subtitle above the title.
   */
  isSubtitleTop?: boolean;
  /**
   * The vertical spacing between the ContentSection and surrounding elements.
   * This spacing controls the distance between the ContentSection and other components on the page.
   */
  spacingY?: ESectionSpacing;
  /**
   * The vertical spacing between the title and the content within the section.
   * This spacing determines the distance between the title and the children elements (section rows).
   */
  titleSpacingY?: ETitleSpacing;
  /**
   * The vertical spacing between rows within the content of the ContentSection.
   * This spacing determines the distance between the children elements (section rows).
   */
  rowSpacingY?: ERowSpacing;
  /**
   * A short section title used to describe the purpose of the section.
   */
  description?: string;
  /**
   * A short section title used to describe the purpose of the section. (at the bottom of the section).
   */
  bottomDescription?: {
    [k: string]: unknown;
  }[];
  /**
   * Background for the whole section.
   */
  background?: ESectionBackground;
  /**
   * Round section.
   */
  isRounded?: boolean;
  /**
   * Size container.
   */
  containerVariant?: EContainerVariant;
  /**
   * Background for container.
   */
  containerBackground?: EContainerBackground;
  /**
   * Round container.
   */
  isContainerRounded?: boolean;

  containerRadiusOnMobile?: EContainerMobileRadius;
  /**
   * Make container full width on mobile.
   */
  isNoPaddingOnMobile?: boolean;
  /**
   * Align text horizontally.
   */
  titleTextAlign?: ETitleTextAlign;
  /**
   * Text color.
   */
  textColor?: ETextColor;
  id?: string;
  dataGaId?: string;
}

const ContentSection = (props: IContentSectionProps): JSX.Element => {
  const {
    id,
    title,
    subtitle,
    isSubtitleTop,
    description,
    bottomDescription,
    spacingY = ESectionSpacing.Xl,
    titleSpacingY = ETitleSpacing.Lg,
    rowSpacingY = ERowSpacing.Base,
    background = ESectionBackground.White,
    titleTextAlign = ETitleTextAlign.Center,
    textColor = ETextColor.Dark,
    isRounded = true,
    containerVariant = EContainerVariant.Lead,
    containerBackground = EContainerBackground.Transparent,
    isContainerRounded = true,
    containerRadiusOnMobile,
    isNoPaddingOnMobile,
    children,
    dataGaId,
  } = props;

  const headClasses = clsx(
    'flex gap-3',
    isSubtitleTop ? 'flex-col-reverse' : 'flex-col',
  );

  const hasSectionPadding = background !== ESectionBackground.Transparent;

  const hasContainerPadding =
    containerBackground !== EContainerBackground.Transparent &&
    background.toString() !== containerBackground.toString();

  let themedTitleStyles = '';
  let themedTextStyles = '';
  let themedSectionStyles = '';
  let eyebrowsTheme = EEyebrowTheme.Primary;
  if (background === ESectionBackground.Theme4) {
    themedSectionStyles = 'bg-surface-1000';
    themedTitleStyles = 'text-interface-50';
    themedTextStyles = 'text-interface-50/80';
    eyebrowsTheme = EEyebrowTheme.Light;
  }

  const isLightText =
    textColor === ETextColor.Light ||
    containerBackground === EContainerBackground.Dark ||
    (containerBackground === EContainerBackground.Transparent &&
      background === ESectionBackground.Dark);

  return (
    <section
      id={id}
      className={clsx('scroll-mt-24', themedSectionStyles, {
        'rounded-3xl lg:rounded-[2rem]': isRounded,
        'py-8': hasSectionPadding && spacingY === ESectionSpacing.Sm,
        'py-8 2xl:py-16':
          hasSectionPadding && spacingY === ESectionSpacing.Base,
        'py-12 2xl:py-16': hasSectionPadding && spacingY === ESectionSpacing.Xl,
        'bg-surface-50': background === ESectionBackground.White,
        'bg-surface-100': background === ESectionBackground.Light,
        'bg-black': background === ESectionBackground.Dark,
      })}
      data-ga-id={dataGaId}
    >
      <Container
        variant={containerVariant}
        containerBackground={containerBackground}
        isContainerRounded={isContainerRounded}
        containerRadiusOnMobile={containerRadiusOnMobile}
        isPadding={hasSectionPadding && hasContainerPadding}
        isNoPaddingOnMobile={isNoPaddingOnMobile}
      >
        <div
          className={clsx({
            'py-8 2xl:py-16':
              hasContainerPadding && spacingY === ESectionSpacing.Base,
            'py-12 2xl:py-16':
              hasContainerPadding && spacingY === ESectionSpacing.Xl,
          })}
        >
          {(title || subtitle) && (
            <div
              className={clsx(
                'mx-auto px-4 text-center empty:hidden lg:max-w-[38.75rem] 2xl:px-0',
                {
                  'mb-6': titleSpacingY === ETitleSpacing.Sm,
                  'mb-8': titleSpacingY === ETitleSpacing.Base,
                  'mb-8 2xl:mb-12': titleSpacingY === ETitleSpacing.Lg,
                },
              )}
            >
              <div className={headClasses}>
                {title && (
                  <h2
                    className={clsx(
                      'whitespace-pre-line text-4xl font-semibold leading-relaxed lg:text-5xl lg:leading-normal 2xl:text-7xl 2xl:leading-snug',
                      themedTitleStyles,
                      {
                        'text-left': titleTextAlign === ETitleTextAlign.Start,
                        'text-interface-1000':
                          !isLightText && !themedTitleStyles,
                        'text-interface-50': isLightText && !themedTitleStyles,
                      },
                    )}
                  >
                    {title}
                  </h2>
                )}
                {subtitle && eyebrowsTheme && (
                  <Eyebrow
                    size={EEyebrowSize.Large}
                    variant={EEyebrowVariant.Text}
                    theme={eyebrowsTheme}
                  >
                    {subtitle}
                  </Eyebrow>
                )}
                {subtitle && !eyebrowsTheme && (
                  <span
                    className={clsx(
                      'text-md block font-medium leading-normal text-brand-500 2xl:text-lg',
                      themedTextStyles,
                    )}
                  >
                    {subtitle}
                  </span>
                )}
              </div>
              {description && (
                <span
                  className={clsx(
                    'text-md mt-3 block font-normal',
                    themedTextStyles,
                    {
                      'text-interface-800': !isLightText && !themedTextStyles,
                      'text-interface-50/70': isLightText && !themedTextStyles,
                    },
                  )}
                >
                  {description}
                </span>
              )}
            </div>
          )}
          <div
            className={clsx('flex w-full flex-col overflow-hidden', {
              'gap-4': rowSpacingY === ERowSpacing.Xs,
              'gap-4 2xl:gap-8': rowSpacingY === ERowSpacing.Base,
              'gap-10 2xl:gap-12': rowSpacingY === ERowSpacing.Lg,
              'gap-12 2xl:gap-16': rowSpacingY === ERowSpacing.Xl,
            })}
          >
            {children}
          </div>

          {bottomDescription && (
            <div
              className={clsx(
                'mx-auto px-4 text-center empty:hidden lg:max-w-[38.75rem] 2xl:px-0',
              )}
            >
              <span
                className={clsx(
                  'text-md mt-8 block font-normal',
                  themedTextStyles,
                  {
                    'text-interface-800': !isLightText && !themedTextStyles,
                    'text-interface-50/70': isLightText && !themedTextStyles,
                  },
                )}
              >
                {<RichText content={bottomDescription} />}
              </span>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default ContentSection;
