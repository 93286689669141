import { DEFAULT_LOCALE } from '@front/shared';
import { Header as THeader } from '@shared/master-types';
import Link from 'next/link';
import React from 'react';

const toLinkProps = (
  prefix: string,
  payload?: NonNullable<
    NonNullable<
      Extract<
        Required<THeader>['header'][number],
        { blockType: 'header-simple' }
      >
    >['menuItems'][number]['action']
  >['link'],
): React.ComponentProps<typeof Link> => {
  if (!payload) {
    throw new Error('Invalid link value provided, expected Page or Url.');
  }

  if (prefix === DEFAULT_LOCALE) {
    prefix = '';
  }

  const nextLinkProps: ReturnType<typeof toLinkProps> = {
    href: payload.url ?? '#',
    style: payload.disabled ? { pointerEvents: 'none' } : {},
    target: payload.newTab ? '_blank' : '_self',
    'aria-disabled': payload.disabled,
    role: 'link',
    rel: (
      payload.rel ||
      (payload as unknown as { fields: { rel: string[] } }).fields?.rel
    )?.join(' '),
  };

  // TODO:check doc.value types
  if (payload?.doc?.value !== '' && typeof payload?.doc?.value === 'string') {
    console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
    console.warn(
      `%cInvalid link reference value provided, expected Page but got string: ${payload.doc.value}`,
      'color: red; font-style: italic',
    );
    return nextLinkProps;
  }

  if (payload.doc?.value) {
    nextLinkProps.href = `${prefix ? '/' + prefix : ''}${
      payload.doc.value.url
    }`;

    if (payload.hash) {
      nextLinkProps.href = `${nextLinkProps.href}#${payload.hash}`;
    }
  }

  return nextLinkProps;
};

export default toLinkProps;
