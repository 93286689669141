import { cva, cx } from 'cva';
import React from 'react';

import { ETabSize, ETabTheme } from './Tab.constants';

export type TTabProps = {
  value: string;
  label: string;
  size: ETabSize;
  theme: ETabTheme;
  onClick: (value: string) => void;
  isActive?: boolean;
};

export const tabOptionCVA = cva('flex snap-center items-center transition', {
  variants: {
    size: {
      [ETabSize.Small]:
        'rounded-lg py-2 px-4 text-sm font-medium leading-relaxed',
      [ETabSize.Medium]:
        'rounded-xl py-3 px-6 text-base font-medium leading-relaxed',
    },
    theme: {
      [ETabTheme.Primary]: '',
      [ETabTheme.Secondary]: '',
    },
    isActive: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      isActive: false,
      theme: ETabTheme.Secondary,
      className: 'bg-control-100 text-interface-1000 hover:bg-control-150',
    },
    {
      isActive: true,
      theme: ETabTheme.Secondary,
      className: 'bg-brand-500 text-brand-50',
    },
  ],
  defaultVariants: {
    isActive: false,
  },
});

const Tab = (props: TTabProps): JSX.Element => {
  const { value, label, isActive, onClick, theme, size } = props;

  return (
    <button
      key={value}
      className={cx(tabOptionCVA({ theme, size, isActive }))}
      role='tab'
      type='button'
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      onClick={() => onClick(value)}
    >
      {label}
    </button>
  );
};

export default Tab;
