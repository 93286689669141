// eslint-disable-next-line @next/next/no-document-import-in-page
import { DocumentProps, Head, Html, Main, NextScript } from 'next/document';

function getLang(lang?: string | string[]): string {
  if (Array.isArray(lang)) {
    return lang[0];
  } else {
    return lang ?? 'en';
  }
}

const appHost = process.env.NEXT_APP_HOST
  ? process.env.NEXT_APP_HOST.toString()
  : '';

const sentryDsnUrl = process.env.NEXT_PUBLIC_SENTRY_DSN_URL
  ? process.env.NEXT_PUBLIC_SENTRY_DSN_URL.toString()
  : '';

const imageCDNHost = process.env.CDN_BASE_URL
  ? process.env.CDN_BASE_URL.toString()
  : '';

export type TBaseBaseDocument = DocumentProps & {
  className?: string;
};

export function BaseDocument(props: TBaseBaseDocument): JSX.Element {
  const { className } = props;

  return (
    <Html
      lang={getLang(props?.__NEXT_DATA__?.query?.locale)}
      className={className}
    >
      <Head />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.NEXT_APP_HOST = '${appHost}';
            window.NEXT_PUBLIC_SENTRY_DSN_URL = '${sentryDsnUrl}';
            window.CDN_BASE_URL = '${imageCDNHost}';
          `,
        }}
      />
      <body>
        <Main />
        <NextScript />
      </body>
    </Html>
  );
}
