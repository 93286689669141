import React from 'react';
import { useForm } from 'react-hook-form';

import { EButtonSize, EButtonTheme, EButtonVariant } from '../../atoms/Button';
import Button from '../../atoms/Button/Button';
import { Input } from '../../atoms/Input';
import {
  EInputField,
  EInputVariant,
  INPUT_CONFIGS,
} from '../../atoms/Input/Input.constants';

export interface IFormSectionProps {
  title: string;
  submitButtonText: string;
  inputs: { name: EInputField; required: boolean }[];
  onSubmit: () => Promise<void>;
  description?: string;
  disclaimer?: string;
}

const FormSection = (props: IFormSectionProps): JSX.Element => {
  const { title, description, inputs, onSubmit, submitButtonText, disclaimer } =
    props;

  const { handleSubmit, register } = useForm({ mode: 'all' });

  return (
    <section className='rounded-3xl bg-black px-4 py-8 2xl:p-12'>
      <div className='flex flex-col gap-6 2xl:flex-row 2xl:justify-between 2xl:gap-40'>
        <div className='flex flex-col'>
          <h2 className='text-4xl font-semibold leading-relaxed text-interface-50 2xl:text-5xl'>
            {title}
          </h2>
          {description && (
            <p className='mt-3 text-sm leading-loose text-interface-50 text-opacity-70 2xl:mt-4 2xl:max-w-sm 2xl:text-base 2xl:leading-extra-loose'>
              {description}
            </p>
          )}
        </div>
        <div className='w-full 2xl:max-w-3xl'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset className='grid gap-x-2 gap-y-3 2xl:grid-cols-2 2xl:gap-x-3'>
              {inputs.map(({ name, ...rest }, index) => (
                <Input
                  key={index}
                  {...INPUT_CONFIGS[name]}
                  {...register(name, rest)}
                  variant={EInputVariant.Primary}
                  placeholder={`${INPUT_CONFIGS[name].placeholder}${
                    rest.required && '*'
                  }`}
                />
              ))}
              <div className='col-start-1 col-end-3 grid'>
                <Button
                  size={EButtonSize.Large}
                  variant={EButtonVariant.Contained}
                  theme={EButtonTheme.Primary}
                  type='submit'
                >
                  {submitButtonText}
                </Button>
              </div>
            </fieldset>
          </form>
          {disclaimer && (
            <span className='mt-3 block text-xs leading-loose text-interface-50 text-opacity-70'>
              {disclaimer}
            </span>
          )}
        </div>
      </div>
    </section>
  );
};

export default FormSection;
