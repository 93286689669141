import Link from 'next/link';
import React from 'react';

import { Container, EVideoSize, TAction, Video } from '../../../../src/index';
import { useDragScrolling } from '../../../hooks/useDragScrolling';

import type { VideoCollection } from '@shared/master-types';

export type TVideosBlockProps = {
  selectedVideos: VideoCollection[];
  actions?: TAction[];
};

export const VideosBlock: React.FC<TVideosBlockProps> = ({
  selectedVideos,
  actions,
}) => {
  const { scrollableRef, bind } = useDragScrolling();

  return (
    <Container>
      {!!selectedVideos?.length && (
        <div
          ref={scrollableRef}
          className='scrollbar-hidden -mr-16 overflow-x-scroll'
          {...bind()}
        >
          <div className='flex flex-row gap-2 md:gap-4'>
            {selectedVideos?.map(currentVideo => {
              return (
                <div
                  key={currentVideo.id}
                  className='max-w-[calc(100vw-2rem)] flex-1 shrink-0 basis-full md:max-w-[25.5rem]'
                >
                  <Video video={currentVideo} size={EVideoSize.M} />
                </div>
              );
            })}
            <div className='w-14 shrink-0 md:w-12' />
          </div>
        </div>
      )}

      {!!actions?.length && (
        <div className='mt-8 w-full text-center 2xl:mt-12'>
          {actions?.map(
            link =>
              link.type === 'link' && (
                <Link
                  key={String(link.props.children)}
                  {...link.props}
                  className='mb-2 flex justify-center rounded-xl bg-surface-100 p-5 text-base font-medium leading-relaxed text-black transition first:mb-0 hover:bg-control-150 aria-disabled:cursor-not-allowed aria-disabled:opacity-40'
                />
              ),
          )}
        </div>
      )}
    </Container>
  );
};
