import clsx from 'clsx';

import { TTextBlockProps } from './TextBlock.types';

export const TextBlock: React.FC<TTextBlockProps> = ({
  title,
  description,
  className,
  ...props
}) => (
  <foreignObject
    xmlns='http://www.w3.org/1999/xhtml'
    y='0%'
    width='100%'
    height={88}
    {...props}
  >
    <div
      className={clsx(
        'text-center',
        'flex',
        'flex-col',
        'justify-center',
        'items-center',
        'h-full',
        className,
      )}
    >
      <div className='text-base'>{description}</div>
      <div className='text-2xl font-semibold leading-relaxed'>{title}</div>
    </div>
  </foreignObject>
);
