import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { ERatingBarSize } from './RatingBar.constants';
import Star from './Star';

export interface IRatingBarProps {
  /**
   * Total votes.
   */
  votesTotal: number;
  /**
   * Total score. Rating is equal to scoreTotal divided by votesTotal.
   */
  scoreTotal: number;
  /**
   * Count of stars. Default "5".
   */
  countStars?: number;
  /**
   * Changing the size of the star and the gap. Default "Small".
   */
  ratingBarSize?: ERatingBarSize;
  /**
   * Checking if the user has voted before. If not used, it will allow you to vote an unlimited number of times. Default "false".
   */
  hasVotedBefore?: boolean;
  /**
   * Returns the selected rating. Use as you need.
   */
  onClick?: (chosenScore: number) => void;
}

const RatingBar = (props: IRatingBarProps): JSX.Element => {
  const {
    votesTotal,
    scoreTotal,
    countStars = 5,
    ratingBarSize = ERatingBarSize.Small,
    hasVotedBefore = false,
    onClick,
  } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [highlightIndex, setHighlightIndex] = useState<number>(0);

  useEffect(() => {
    if (!isHovered || (isHovered && hasVotedBefore)) {
      setHighlightIndex(scoreTotal / votesTotal);
    }
  }, [scoreTotal, votesTotal, isHovered, hasVotedBefore]);

  const handleMouseEnter = (starIndex: number): void => {
    setIsHovered(true);
    !hasVotedBefore && setHighlightIndex(starIndex);
  };

  const handleMouseLeave = (): void => {
    setIsHovered(false);
    setHighlightIndex(scoreTotal / votesTotal);
  };

  const handleClick = (starIndex: number, chosenStarScore: number): void => {
    if (!hasVotedBefore && onClick) {
      onClick(starIndex + chosenStarScore);
    }
  };

  return (
    <div className='flex select-none items-center gap-4'>
      <div
        className={clsx(
          'flex',
          ratingBarSize === ERatingBarSize.Small && '-mx-1',
          ratingBarSize === ERatingBarSize.Large && '-mx-1.5',
        )}
      >
        {Array.from({ length: countStars }).map((_, starIndex: number) => (
          <Star
            key={starIndex}
            starIndex={starIndex}
            ratingBarSize={ratingBarSize}
            hasVotedBefore={hasVotedBefore}
            highlightIndex={Number(highlightIndex.toFixed(1))}
            onMouseEnter={() => handleMouseEnter(starIndex)}
            onMouseLeave={handleMouseLeave}
            onClick={(chosenStarScore: number): void =>
              handleClick(starIndex, chosenStarScore)
            }
          />
        ))}
      </div>
      {scoreTotal > 0 && votesTotal > 0 && countStars > 0 && (
        <div className='leading-5 flex items-center gap-1 text-sm font-medium '>
          <span className='text-brand-500'>
            {Number((scoreTotal / votesTotal).toFixed(1)) < countStars
              ? (scoreTotal / votesTotal).toFixed(1)
              : countStars}
            /{countStars}
          </span>
          <span className='text-interface-500'>
            (
            {votesTotal > 999
              ? (votesTotal / 1000).toString().split('.')[0] + 'k'
              : votesTotal}
            )
          </span>
        </div>
      )}
    </div>
  );
};

export default RatingBar;
