import { ERatingBarSize } from './RatingBar.constants';
import StarLayer from './StarLayer';

export interface IRatingStarProps {
  starIndex: number;
  ratingBarSize: ERatingBarSize;
  highlightIndex: number;
  hasVotedBefore?: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (chosenStarScore: number) => void;
}

const Star = (props: IRatingStarProps): JSX.Element => {
  const {
    starIndex,
    ratingBarSize,
    highlightIndex,
    hasVotedBefore,
    onMouseEnter,
    onMouseLeave,
    onClick,
  } = props;

  return (
    <div
      className='star relative flex'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StarLayer
        isHalfStarLayer
        ratingBarSize={ratingBarSize}
        hasVotedBefore={hasVotedBefore}
        onClick={() => onClick(0.5)}
        shouldHighlight={
          highlightIndex &&
          highlightIndex - starIndex > 0 &&
          highlightIndex - starIndex <= 0.5
            ? true
            : false
        }
      />
      <StarLayer
        ratingBarSize={ratingBarSize}
        hasVotedBefore={hasVotedBefore}
        onClick={() => onClick(1)}
        shouldHighlight={
          highlightIndex && starIndex < highlightIndex - 0.5 ? true : false
        }
      />
    </div>
  );
};

export default Star;
