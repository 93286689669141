import {
  BlogArchive,
  BlogArticle,
  BlogAuthor,
  BlogEvent,
  Document,
  Page,
} from '@shared/master-types';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { ReactComponent as IconAvatar } from '../../../../public/icons/avatar.svg';
import toLinkParam from '../../../utils/toLinkParam';
import toLinkProps from '../../../utils/toLinkProps';
import { RichText, TRichTextContent } from '../RichText';

export type TQuoteDocProps =
  | {
      value: string | Page;
      relationTo: 'pages';
    }
  | {
      value: string | BlogArchive;
      relationTo: 'blog-archives';
    }
  | {
      value: string | BlogArticle;
      relationTo: 'blog-articles';
    }
  | {
      value: string | BlogAuthor;
      relationTo: 'blog-authors';
    }
  | {
      value: string | BlogEvent;
      relationTo: 'blog-events';
    }
  | {
      value: string | Document;
      relationTo: 'documents';
    };

export type TQuoteLinkProps = {
  text: string;
  linkType: 'internal' | 'custom';
  url: string;
  doc: TQuoteDocProps;
  hash?: string;
  newTab?: boolean;
  disabled?: boolean;
  rel: ('nofollow' | 'noopener' | 'noreferrer' | 'external')[];
  id?: string;
}[];

export type TQuoteProps = {
  variant?: string;
  slogan?: string;
  quote?: TRichTextContent;
  sourceText?: string;
  sourcePrefix?: string;
  link?: TQuoteLinkProps;
  locale: string;
  jurisdiction: string;
  isOpenInNewTab?: boolean;
};

export const Quote: React.FC<TQuoteProps> = props => {
  const {
    variant = 'Default',
    slogan,
    quote,
    sourceText,
    sourcePrefix,
    link,
    locale,
    jurisdiction,
    isOpenInNewTab,
  } = props;

  const linkParam = toLinkParam({
    locale: locale?.toString(),
    jurisdiction: jurisdiction?.toString(),
  });

  return (
    <blockquote className='py-4'>
      <div
        className={clsx(
          'flex flex-col gap-3 overflow-y-hidden rounded-[1.25rem] p-6',
          variant === 'Default' && 'relative bg-surface-100',
          variant === 'Outlined' && 'border-[0.1875rem] border-brand-500',
          variant === 'PrimaryBG' && 'bg-brand-500 text-white',
        )}
      >
        {variant === 'Default' && (
          <div className='absolute right-0 top-0 h-full w-1 bg-brand-500' />
        )}
        {slogan && (
          <h4
            className={clsx(
              'text-sm font-medium',
              variant !== 'PrimaryBG' && 'text-brand-500',
            )}
          >
            {slogan}
          </h4>
        )}
        <div
          className={clsx(
            'break-words',
            'prose-a:text-brand-500 prose-a:transition hover:prose-a:text-brand-550',
            'prose-p:py-2 prose-p:text-base prose-p:font-semibold prose-p:italic prose-p:leading-extra-loose prose-p:text-interface-1000',
            variant === 'PrimaryBG' &&
              'prose-p:text-white prose-a:text-white prose-a:underline prose-a:underline-offset-2 hover:prose-a:text-white hover:prose-a:underline-offset-4',
          )}
        >
          <RichText isOpenInNewTab={isOpenInNewTab} content={quote} />
        </div>
        {sourceText && (
          <div className='flex items-center gap-1'>
            <span className='mr-1'>
              <IconAvatar />
            </span>
            {sourcePrefix && (
              <span
                className={clsx(
                  'text-xs',
                  variant !== 'PrimaryBG' && 'text-interface-500',
                )}
              >
                {sourcePrefix}
              </span>
            )}
            <h5
              className={clsx(
                'text-xs',
                variant !== 'PrimaryBG' && 'text-control-950',
              )}
            >
              {sourceText}
            </h5>
          </div>
        )}
        {link?.map(linkItem => {
          const linkWithProps = toLinkProps(linkParam, linkItem);

          return (
            <div key={linkItem.id}>
              <Link
                className={clsx(
                  'pt-1 text-xs transition',
                  variant !== 'PrimaryBG'
                    ? 'text-control-950 transition hover:text-brand-550'
                    : 'text-white underline underline-offset-2 hover:text-white hover:underline-offset-4',
                )}
                {...linkWithProps}
              >
                {linkItem.text}
              </Link>
            </div>
          );
        })}
      </div>
    </blockquote>
  );
};
