import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

import { ReactComponent as FacebookLogo } from '../../../../public/icons/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from '../../../../public/icons/instagram-logo.svg';
import { ReactComponent as LinkedInLogo } from '../../../../public/icons/linked-in-logo.svg';
import { ReactComponent as TelegramLogo } from '../../../../public/icons/telegram-logo.svg';
import { ReactComponent as TwitterLogo } from '../../../../public/icons/twitter-logo.svg';
import { ReactComponent as WeiboLogo } from '../../../../public/icons/weibo-logo.svg';
import { ReactComponent as WeixinLogo } from '../../../../public/icons/weixin-logo.svg';
import { ReactComponent as YoukuLogo } from '../../../../public/icons/youku-logo.svg';
import { ReactComponent as YoutubeLogo } from '../../../../public/icons/youtube-logo.svg';
import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '../../atoms/Button';
import { Tag, ETagSize, ETagTheme, ETagVariant } from '../../atoms/Tag';

import type { TAction } from '../../../types';

const AppStoreButton = dynamic<{ className?: string }>(() =>
  import(
    /* webpackChunkName: "AppStoreButton" */ '../../../../public/icons/button-app-store.svg'
  ).then(r => r.ReactComponent),
);

const GooglePlayButton = dynamic<{ className?: string }>(() =>
  import(
    /* webpackChunkName: "GooglePlayButton" */ '../../../../public/icons/button-google-play.svg'
  ).then(r => r.ReactComponent),
);

export interface IFooterOneColumnProps {
  hasLine?: boolean;
  currentLocale?: string;
  locales?: { value: string; label: string; country: string }[];
  callToAction?: {
    title?: string;
    description?: string;
    actions?: TAction[];
  };
  description?: JSX.Element | null | string;
  docs?: TAction[];
  contacts?: {
    label: string;
    value: string | { textElement?: string }[];
    type: 'email' | 'telephone' | 'textRow';
  }[];
  locations?: { label: string; value: string; url: string }[];
  downloads?: {
    appStore?: {
      url?: string;
      qrCode?: ImageProps | null;
    };
    googlePlay?: {
      url?: string;
      qrCode?: ImageProps | null;
    };
  };
  socials?: {
    instagram?: string;
    linkedIn?: string;
    youtube?: string;
    facebook?: string;
    twitter?: string;
    telegram?: string;
    weibo?: string;
    weixin?: string;
    youku?: string;
  };
}

const FooterOneColumn = (props: IFooterOneColumnProps): JSX.Element => {
  const {
    hasLine,
    currentLocale,
    locales = [],
    callToAction,
    description,
    downloads = {},
    docs = [],
    contacts = [],
    locations = [],
    socials = {},
  } = props;

  const currentLocaleData = locales.find(l => l.value === currentLocale);

  return (
    <footer className='pt-12 2xl:pt-16'>
      <div className='rounded-[1.75rem_1.75rem_0_0] bg-surface-100 px-4 py-12 2xl:px-24 2xl:py-16'>
        {callToAction && (
          <section className='flex flex-col items-center text-center text-base font-medium lg:text-lg'>
            {callToAction.description && (
              <Tag
                variant={ETagVariant.Text}
                size={ETagSize.Medium}
                theme={ETagTheme.Primary}
              >
                {callToAction.description}
              </Tag>
            )}
            {callToAction.title && (
              <h2 className='mt-3 w-full max-w-[21.438rem] whitespace-pre-line text-4xl font-semibold leading-relaxed text-interface-1000 lg:leading-normal xl:max-w-[38.75rem] xl:text-7xl xl:leading-snug'>
                {callToAction.title}
              </h2>
            )}
            {!!callToAction.actions?.length && (
              <div className='mt-6 w-full self-center xl:w-auto'>
                {callToAction.actions.map((action, index) =>
                  action.type === 'button' ? (
                    <React.Fragment key={index}>
                      <div className='hidden xl:block'>
                        <Button
                          {...action.props}
                          size={EButtonSize.Medium}
                          variant={EButtonVariant.Contained}
                          theme={EButtonTheme.Primary}
                        />
                      </div>
                      <div className='grid w-full xl:hidden'>
                        <Button
                          {...action.props}
                          size={EButtonSize.Large}
                          variant={EButtonVariant.Contained}
                          theme={EButtonTheme.Primary}
                        />
                      </div>
                    </React.Fragment>
                  ) : null,
                )}
              </div>
            )}
          </section>
        )}
        {hasLine && <div className='my-12 h-px bg-surface-200' />}
        {(contacts.length !== 0 ||
          ((downloads.appStore?.qrCode || downloads.googlePlay?.qrCode) &&
            (downloads.appStore?.url || downloads.googlePlay?.url))) && (
          <section
            className={clsx(
              'relative mt-12 flex flex-col rounded-2xl bg-surface-50 p-6 md:items-center xl:flex-row xl:p-8',
              !!contacts.length &&
                (downloads.appStore?.qrCode || downloads.googlePlay?.qrCode)
                ? 'xl:justify-between'
                : 'xl:justify-center',
            )}
          >
            {(downloads.appStore?.qrCode || downloads.googlePlay?.qrCode) && (
              <div className='hidden gap-10 xl:flex'>
                {downloads.appStore?.qrCode && (
                  <Image
                    {...downloads.appStore?.qrCode}
                    className='max-w-[8rem] object-contain object-left'
                  />
                )}
                {downloads.googlePlay?.qrCode && (
                  <Image
                    {...downloads.googlePlay?.qrCode}
                    className='max-w-[8rem] object-contain object-left'
                  />
                )}
              </div>
            )}

            {!!contacts.length && (
              <div className='flex flex-col flex-wrap gap-5 md:flex-row 2xl:gap-10'>
                {contacts.map(contact => (
                  <div
                    key={contact.label}
                    className={clsx(
                      'flex w-full flex-col gap-1  xl:w-auto xl:max-w-[18rem] 2xl:max-w-[23.5rem]',
                      contact.type === 'email' && '2xl:order-1',
                    )}
                  >
                    <span className='text-xs leading-loose text-interface-500'>
                      {contact.label}
                    </span>
                    {contact &&
                    !Array.isArray(contact.value) &&
                    contact.type === 'email' ? (
                      <a
                        href={`mailto:${contact.value}`}
                        target='_blank'
                        rel='noreferrer noopener'
                        className='text-sm leading-loose text-control-950'
                      >
                        {contact.value}
                      </a>
                    ) : contact &&
                      !Array.isArray(contact.value) &&
                      contact.type === 'telephone' ? (
                      <a
                        href={`tel:${contact.value.replace(/ /g, '')}`}
                        target='_blank'
                        rel='noreferrer noopener'
                        className='text-sm leading-loose text-control-950'
                      >
                        {contact.value}
                      </a>
                    ) : contact &&
                      Array.isArray(contact.value) &&
                      contact.type === 'textRow' ? (
                      contact.value?.map(textRowItem => (
                        <p
                          key={textRowItem.textElement}
                          className={'text-sm leading-loose text-control-950'}
                        >
                          {textRowItem.textElement}
                        </p>
                      ))
                    ) : null}
                  </div>
                ))}
              </div>
            )}

            {(downloads.appStore?.url || downloads.googlePlay?.url) && (
              <div
                className={clsx('flex items-center gap-4 xl:hidden', {
                  'mt-8': !!contacts.length,
                })}
              >
                {downloads.appStore?.url && (
                  <a
                    href={downloads.appStore.url}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='flex h-9'
                  >
                    <AppStoreButton className='h-full object-contain' />
                  </a>
                )}
                {downloads.googlePlay?.url && (
                  <a
                    href={downloads.googlePlay.url}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='flex h-9'
                  >
                    <GooglePlayButton className='h-full object-contain' />
                  </a>
                )}
              </div>
            )}
          </section>
        )}
        <div className='border-b-surface-200 pb-6 pt-12 2xl:pt-16'>
          <div
            className={clsx('flex flex-col items-center gap-8 text-center', {
              '2xl:flex-row 2xl:justify-between':
                locations.length > 1 ||
                (Object.keys(socials).length !== 0 && !!locations.length),
            })}
          >
            {locations.map(location => (
              <span
                key={location.value}
                className='text-interface-950 w-3/4 text-sm leading-loose 2xl:w-auto'
              >
                {location.value}
              </span>
            ))}
            <div className='flex gap-5 text-control-600 empty:hidden'>
              {socials.twitter && (
                <a
                  href={socials.twitter}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <TwitterLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
              {socials.linkedIn && (
                <a
                  href={socials.linkedIn}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <LinkedInLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
              {socials.instagram && (
                <a
                  href={socials.instagram}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <InstagramLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
              {socials.telegram && (
                <a
                  href={socials.telegram}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <TelegramLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
              {socials.facebook && (
                <a
                  href={socials.facebook}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <FacebookLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
              {socials.youtube && (
                <a
                  href={socials.youtube}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <YoutubeLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
              {socials.weibo && (
                <a
                  href={socials.weibo}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <WeiboLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
              {socials.weixin && (
                <a
                  href={socials.weixin}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <WeixinLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
              {socials.youku && (
                <a
                  href={socials.youku}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <YoukuLogo className='aspect-square h-4 w-4 object-contain object-center transition hover:text-control-650' />
                </a>
              )}
            </div>
          </div>

          {(description || currentLocaleData || !!docs.length) && (
            <div className='mt-4 flex flex-col gap-8 lg:gap-4'>
              {description && (
                <div className='text-left text-sm leading-loose text-interface-500 empty:hidden'>
                  {description}
                </div>
              )}

              {(currentLocaleData || !!docs.length) && (
                <div className='flex flex-wrap gap-x-5 gap-y-4 text-sm font-medium text-control-600 empty:hidden 2xl:gap-x-6'>
                  {currentLocaleData && (
                    <div className='flex items-center gap-1.5'>
                      <Image
                        width={16}
                        height={13}
                        src={`/icons/flags/${currentLocaleData.country}.svg`}
                        className='h-3 w-4 overflow-hidden rounded-[0.1875rem] object-cover object-center'
                        alt={currentLocaleData.label}
                      />
                      {currentLocaleData.label}
                    </div>
                  )}
                  {docs.map(
                    (doc, i) =>
                      doc.type === 'link' && (
                        <Link
                          key={i}
                          {...doc.props}
                          className='transition hover:text-control-650'
                        />
                      ),
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default FooterOneColumn;
