import {
  DefaultCard,
  DefaultCards,
  TDefaultCardProps,
  ETitleSize,
  ETitleType,
  RichText,
  toImageProps,
  ETagVariant,
  ETagSize,
  EIconSize,
  ETagTheme,
  EDefaultCardLayout,
  EDefaultCardBackground,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import { TAppDispatch } from '../../../store/store.types';
import toCardButtons from '../../../utils/toCardButtons';

export type TDefaultCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'default-cards' }
>;

export const prepareDefaultCardFromRawData = (
  card: Required<TDefaultCardsConnectedProps>['cards'][number],
  locale: string,
  dispatch: TAppDispatch,
): TDefaultCardProps => {
  const {
    icon,
    iconSize,
    titleSize,
    titleType,
    description,
    banner,
    tags,
    badges,
    badgesStyle,
    badgesSize,
    badgesTheme,
    size,
    background,
    downloads,
  } = card;

  return {
    ...card,
    icon: toImageProps(icon),
    iconSize: iconSize as EIconSize,
    titleSize: titleSize as ETitleSize,
    titleType: titleType as ETitleType,
    description: <RichText content={description} />,
    image: toImageProps(banner?.media),
    swapImageSide: banner?.swapImageSide,
    tags: tags?.map(tag => ({ children: tag.text })),
    badges: badges?.map(badge => ({ children: badge.text })),
    badgesStyle: badgesStyle as ETagVariant,
    badgesSize: badgesSize as ETagSize,
    badgesTheme: badgesTheme as ETagTheme,
    buttons: toCardButtons(card.buttons, locale, dispatch),
    size: size as EDefaultCardLayout,
    background: background as EDefaultCardBackground,
    addons: {
      downloads: {
        appStoreUrl: downloads?.appStore,
        googlePlayUrl: downloads?.googlePlay,
      },
    },
  };
};
const DefaultCardsConnected = (
  props: TDefaultCardsConnectedProps,
): JSX.Element => {
  const { cards = [], ...rest } = props;
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');
  const banners: React.ComponentProps<typeof DefaultCard>[] = useMemo(() => {
    return cards.map(card => {
      return prepareDefaultCardFromRawData(card, currentLocale, appDispatch);
    });
  }, [cards, currentLocale, appDispatch]);

  return <DefaultCards banners={banners} {...rest} />;
};

export default DefaultCardsConnected;
