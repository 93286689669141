import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { EBannerCardsShowAs } from './BannerCards.constants';
import { Container } from '../../sections/Container';
import BannerCard from '../BannerCard/BannerCard';

export interface IBannerCardsProps {
  banners: React.ComponentProps<typeof BannerCard>[];
  showAs: EBannerCardsShowAs | string;
  showNumbersBeforeTabName?: boolean;
}

const BannerCards = (props: IBannerCardsProps): JSX.Element => {
  const { banners, showAs, showNumbersBeforeTabName = true } = props;

  const buttonsWrapRef = useRef<HTMLDivElement>(null);

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    if (buttonsWrapRef.current) {
      const paddingLeft = parseFloat(
        window
          .getComputedStyle(buttonsWrapRef.current)
          .getPropertyValue('padding-left'),
      );
      const left = buttonsWrapRef.current.children
        .item(currentBannerIndex)
        ?.getBoundingClientRect().left;

      buttonsWrapRef.current.scrollLeft = left
        ? left - paddingLeft
        : paddingLeft;
    }
  }, [currentBannerIndex]);

  return (
    <section
      className={clsx(
        'grid',
        showAs === EBannerCardsShowAs.Stack && 'gap-4 2xl:gap-8',
        showAs === EBannerCardsShowAs.Tabs && 'gap-6',
      )}
    >
      {showAs === EBannerCardsShowAs.Tabs && (
        <div
          ref={buttonsWrapRef}
          className='scrollbar-hidden flex gap-x-6 gap-y-2 overflow-auto scroll-smooth px-4 lg:container lg:flex-wrap lg:justify-center 2xl:gap-x-10 2xl:gap-y-4'
        >
          {banners.map((card, index) => (
            <button
              key={index}
              className={clsx(
                'flex items-center gap-2 whitespace-nowrap text-lg font-medium leading-normal transition',
                currentBannerIndex === index &&
                  'text-brand-500 hover:text-brand-550',
                currentBannerIndex !== index &&
                  'text-control-600 hover:text-brand-500',
              )}
              onClick={() => setCurrentBannerIndex(index)}
            >
              {showNumbersBeforeTabName && (
                <span className='flex aspect-square h-5 w-5 items-center justify-center rounded-[6px] bg-current text-xs leading-[1]'>
                  <span className='text-white'>{index + 1}</span>
                </span>
              )}
              {card.title}
            </button>
          ))}
        </div>
      )}
      {showAs === EBannerCardsShowAs.Tabs && (
        <Container>
          <BannerCard {...banners[currentBannerIndex]} />
        </Container>
      )}
      {showAs === EBannerCardsShowAs.Stack && (
        <Container>
          {banners.map((banner, i) => (
            <div key={i} className='mb-4 last:mb-0'>
              <BannerCard {...banner} />
            </div>
          ))}
        </Container>
      )}
      {showAs === EBannerCardsShowAs.Scroll && (
        <div className='scrollbar-hidden overflow-auto scroll-smooth'>
          <Container>
            <div className='flex gap-4'>
              {banners.map((banner, i) => (
                <div key={i} className='min-w-[95%] lg:min-w-full'>
                  <BannerCard {...banner} />
                </div>
              ))}
            </div>
          </Container>
        </div>
      )}
    </section>
  );
};

export default BannerCards;
