import { consoleLogAppVersion, DEFAULT_LOCALE } from '@front/shared';
import { propagateRoistat, GtmTagManager } from '@front/shared/ds';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';

import { CookieBanner, ModalsRoot } from '../..';
import wrapper from '../../../store/index';
import { isMobile } from '../../../utils/isMobile';

import type { AppProps } from 'next/app';

consoleLogAppVersion();

export type TBaseAppProps = AppProps & {
  gtmId?: string;
  roistatId?: string;
  className?: string;
};

export function BaseApp({
  Component,
  gtmId,
  roistatId = 'f500ee62d5e848446a10765f390f1447',
  className,
  ...rest
}: TBaseAppProps): JSX.Element {
  const { store, props } = wrapper.useWrappedStore(rest);

  const router = useRouter();
  const locale = String(router.query.locale || DEFAULT_LOCALE);

  useEffect(() => {
    if (locale) {
      document.documentElement.lang = locale;
    }
  }, [locale]);

  useEffect(() => {
    if (roistatId) {
      propagateRoistat({ projectId: roistatId });
    }
  }, [roistatId]);

  return (
    <Provider store={store}>
      {!!gtmId && <GtmTagManager gtmId={gtmId} />}
      <div id='_app' className={className}>
        <ModalsRoot />
        <CookieBanner />
        <Component {...props.pageProps} />
      </div>
    </Provider>
  );
}

export function reportWebVitals(metric: { [key: string]: unknown }): void {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const body = JSON.stringify({
    ...metric,
    pathname: location.pathname,
    isMobile: isMobile(),
  });
  const url = `${window.location.origin}/api/register-webvitals-metrics`;

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', keepalive: true }).catch(console.error);
  }
}
