import { addJurisdictionSupport } from '@front/shared/utils';
import { Jurisdiction, Locale } from '@shared/master-types';

import { EActionType, EPageTypes, TAction, toLinkProps } from '..';

type TPageType =
  | EPageTypes.Pages
  | EPageTypes.BlogArchives
  | EPageTypes.BlogArticles
  | EPageTypes.BlogEvents
  | EPageTypes.Documents;

export default function buildTactionLinkWithJurisdictionSupport(
  title: string,
  relationTo: TPageType,
  currentLocale: string,
  defUrl?: string,
  slug?: string,
  locales?: Locale[],
  jurisdictionList?: Jurisdiction[],
): TAction {
  const preparedTActions: TAction[] = [
    {
      type: EActionType.LinkType,
      props: {
        ...toLinkProps(currentLocale, {
          text: title,
          url: defUrl || '',
          linkType: 'internal',
          doc: { value: '', relationTo: relationTo },
          rel: [],
        }),
      },
    },
  ];

  return addJurisdictionSupport({
    jurisdictionList: jurisdictionList,
    localeList: locales,
    slug: slug,
    actionList: preparedTActions,
  })[0];
}
