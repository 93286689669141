import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import { Container } from '../../sections/Container';

export interface ICountriesRowSection {
  regions?: {
    region: string;
    country?: {
      countryName: string;
      countryIso2: string;
    }[];
  }[];
}

const CountriesRowSection = (props: ICountriesRowSection): JSX.Element => {
  const { regions = [] } = props;

  return (
    <Container>
      <div className='grid h-full grid-cols-12 items-start gap-4 gap-y-8 empty:hidden'>
        {regions.map((region, regionIndex) => (
          <div
            key={region.region}
            className={clsx(
              regionIndex === 0
                ? 'col-span-12 2xl:col-span-8'
                : 'col-span-12 2xl:col-span-4',
              'grid gap-y-4 2xl:flex 2xl:h-full 2xl:flex-col',
            )}
          >
            <h3 className='w-full rounded-2xl bg-surface-100 py-5 text-center text-2xl font-semibold leading-relaxed text-interface-1000'>
              {region.region}
            </h3>
            <div
              className={clsx(
                'grid auto-rows-min grid-cols-2 gap-y-4 rounded-2xl bg-surface-100 py-6 2xl:h-full',
                regionIndex === 0
                  ? 'gap-x-8 px-8 lg:grid-cols-3 2xl:grid-cols-4 2xl:px-16'
                  : 'gap-x-3 px-8 lg:grid-cols-3 2xl:grid-cols-2',
              )}
            >
              {region.country?.map(country => (
                <div
                  key={country.countryName}
                  className='flex flex-nowrap items-center  py-2'
                >
                  <div className='flex items-start gap-1'>
                    <div className='min-w-[20px] flex-shrink-0'>
                      <Image
                        width={20}
                        height={16}
                        src={`/icons/flags/${country.countryIso2}.svg`}
                        alt={country.countryName}
                        className='mt-1 rounded-sm'
                      />
                    </div>
                    <span className='ml-0.5 flex-grow text-base font-medium leading-relaxed text-interface-800'>
                      {country.countryName}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default CountriesRowSection;
