import { Breadcrumbs } from '@front/shared/ds';
import React, { ReactNode } from 'react';

import {
  FooterParser,
  HeaderParser,
  TMetaProps,
  Meta,
} from '../../../components';
import {
  useGetFooterQuery,
  useGetHeaderQuery,
  useGetMetaQuery,
  useGetLocalesQuery,
} from '../../../features';

import type { Page } from '@shared/master-types';

type TPageLayoutProps = {
  locale: string;
  draft?: boolean;
  children: ReactNode;
  meta?: Partial<TMetaProps>;
  breadcrumbs?: Page['breadcrumbs'];
};

export const PageLayout: React.FC<TPageLayoutProps> = props => {
  const { draft, locale, children, meta: metaProps, breadcrumbs } = props;

  const { data: headerDefaultObject } = useGetHeaderQuery({ locale });
  const { data: footerDefaultObject } = useGetFooterQuery({ locale });
  const { data: localesObject } = useGetLocalesQuery({ locale, draft });
  const { data: meta } = useGetMetaQuery({ locale });

  const locales = localesObject?.docs;
  const header = headerDefaultObject?.docs?.[0];
  const footer = footerDefaultObject?.docs?.[0];

  return (
    <>
      {!!meta && <Meta favicon={meta?.favicon} {...metaProps} />}

      {!!header && <HeaderParser headers={header} locales={locales} />}

      <main>
        {!!breadcrumbs?.length && (
          <Breadcrumbs
            items={breadcrumbs}
            locale={locales?.length ? locale : ''}
          />
        )}
        {children}
      </main>

      {footer && <FooterParser footers={footer} locales={locales} />}
    </>
  );
};
