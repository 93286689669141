import { Event, toImageProps, toLinkParam } from '@front/shared/ds';
import { BlogEvent, Setting } from '@shared/master-types';
import { useRouter } from 'next/router';
import React from 'react';

export type TEventConnectedProps = BlogEvent & {
  readingTimeMinute?: string;
  eventSettings?: Setting['singleEvent'];
};
const EventConnected = (props: TEventConnectedProps): JSX.Element => {
  const { createdAt, updatedAt, cover, eventSettings, ...restProps } = props;

  const eventTagValue = eventSettings?.eventTag?.value;
  const eventTag =
    eventTagValue && typeof eventTagValue !== 'string' ? eventTagValue : null;

  const router = useRouter();
  const linkParam = toLinkParam({
    locale: router.query.locale?.toString(),
    jurisdiction: router.query.jurisdiction?.toString(),
  });

  const eventTagPath = eventTag
    ? `${linkParam && '/'}${linkParam}/${eventTag.slug || ''}`
    : '';

  return (
    <Event
      {...restProps}
      updatedAt={updatedAt}
      publishedAt={createdAt}
      cover={toImageProps(cover, { useFill: true })}
      eventTagTitle={eventTag?.name}
      eventTagPath={eventTagPath}
    />
  );
};

export default EventConnected;
