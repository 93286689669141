import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import React, { useState } from 'react';

export interface IAccordionProps {
  title: string;
  description?: string;
  _description?: JSX.Element | null;
}

const Accordion = (props: IAccordionProps): JSX.Element => {
  const { description, title, _description } = props;

  const [animationParent] = useAutoAnimate();

  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <section
      ref={animationParent}
      className='cursor-pointer rounded-3xl bg-control-100 px-8 py-6 transition hover:bg-control-150'
      onClick={() => setIsCollapsed(c => !c)}
    >
      <h3 className='text-xl font-semibold leading-normal text-interface-1000'>
        {title}
      </h3>
      {!isCollapsed && !_description && (
        <p className='mt-4 text-base leading-extra-loose text-interface-800'>
          {description}
        </p>
      )}
      {!isCollapsed && _description && (
        <div
          className={clsx(
            'mt-4 text-base leading-extra-loose text-interface-800',
            'prose-ul:grid prose-ul:list-disc prose-ul:gap-2 prose-ul:pl-6',
            'prose-ol:grid prose-ol:list-decimal prose-ol:gap-2 prose-ol:pl-6',
          )}
        >
          {_description}
        </div>
      )}
    </section>
  );
};

export default Accordion;
