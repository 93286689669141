import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import {
  Button,
  Container,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
  EContainerVariant,
} from '../../..';
import { ReactComponent as IconErrorSquare } from '../../../../public/icons/components/sectionError/error_square.svg';
import { ReactComponent as IconSearchLoop } from '../../../../public/icons/components/sectionError/search_loop.svg';
import { ReactComponent as IconSearchLoopDark } from '../../../../public/icons/components/sectionError/search_loop_dark.svg';
import { ReactComponent as IconSomethingWentWrongDark } from '../../../../public/icons/components/sectionError/something-went-wrong-dark.svg';
import { ReactComponent as IconSomethingWentWrong } from '../../../../public/icons/components/sectionError/something-went-wrong.svg';

export interface ISectionError {
  errorText?: string;
  buttonText?: string;
  statusCode: number;
  darkTheme?: boolean;
}

export const SectionError: React.FC<ISectionError> = props => {
  const { errorText, buttonText, statusCode, darkTheme = false } = props;

  const router = useRouter();

  return (
    <Container variant={EContainerVariant.Lead} isPadding={false}>
      <div
        className={clsx(
          'relative mx-0 mb-12 flex min-h-[21.938rem] flex-col justify-between rounded-[2rem]  px-4 pb-[1rem] pt-[9.75rem] xl:min-h-[31.5rem] xl:pb-[8.125rem] xl:pt-[8.125rem]',
          {
            'bg-surface-100': !darkTheme,
            'bg-surface-1000': darkTheme,
          },
        )}
      >
        <h1
          className={clsx(
            'mb-[2rem] w-full text-center text-6xl font-semibold leading-relaxed  lg:mb-8 xl:text-9xl 2xl:mb-12 2xl:leading-tight',
            {
              'text-interface-1000': !darkTheme,
              'text-interface-50': darkTheme,
            },
          )}
        >
          {statusCode === 404 && <span>Oops... </span>}
          {statusCode}
          <br />
          {errorText}
        </h1>
        {buttonText && (
          <div className={'mx-auto w-full max-w-full xl:max-w-fit'}>
            <Button
              className={'w-full'}
              onClick={() => router.push('/')}
              size={EButtonSize.Large}
              variant={EButtonVariant.Contained}
              theme={darkTheme ? EButtonTheme.Secondary : EButtonTheme.Primary}
            >
              {buttonText}
            </Button>
          </div>
        )}
        {IconErrorSquare && (
          <div
            className={
              'absolute hidden xl:left-[2.5rem] xl:top-[2.25rem] xl:block xl:h-[13.1rem] xl:w-[13.1rem]'
            }
          >
            <IconErrorSquare className={'h-full w-full text-brand-500'} />
          </div>
        )}
        <div
          className={
            'absolute right-[2rem] top-[0.438rem]  h-[8.125rem] w-[10rem] md:right-[10rem] lg:right-[14.3rem] xl:bottom-[2.5rem] xl:right-[3.25rem] xl:top-auto xl:h-[12.5rem] xl:w-[15.1rem] '
          }
        >
          {!darkTheme && IconSomethingWentWrong ? (
            <IconSomethingWentWrong
              className={'h-full w-full text-brand-500'}
            />
          ) : (
            IconSomethingWentWrongDark && (
              <IconSomethingWentWrongDark
                className={'h-full w-full text-brand-500'}
              />
            )
          )}
        </div>

        <div
          className={
            'absolute left-[2.5rem] top-[1.688rem]  h-[4.375rem]  w-[4.375rem] md:left-[11rem] md:h-[5.563.rem] md:w-[5.563rem] lg:left-[15rem] xl:left-auto xl:right-[5.938rem] xl:top-[1.5rem] xl:h-[9.5rem] xl:w-[9.5rem]'
          }
        >
          {!darkTheme && IconSearchLoop ? (
            <IconSearchLoop className={'h-full w-full text-brand-500'} />
          ) : (
            IconSearchLoopDark && (
              <IconSearchLoopDark className={'h-full w-full text-brand-500'} />
            )
          )}
        </div>
      </div>
    </Container>
  );
};
