import { getGaId } from '@front/shared/utils';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  EHorizontalCardsShowAs,
  EHorizontalCardsTabButtonStyle,
} from './HorizontalCards.constants';
import { Button, EButtonSize, EButtonTheme } from '../../atoms/Button';
import Container from '../../sections/Container/Container';
import HorizontalCard from '../HorizontalCard/HorizontalCard';

export interface IHorizontalCardsProps {
  banners: React.ComponentProps<typeof HorizontalCard>[];
  showAs: EHorizontalCardsShowAs | string;
  tabButtonStyle: EHorizontalCardsTabButtonStyle | string;
  blockName?: string;
}

const BUTTONS_CONTAINER_GAP_SIZE_PX = 8;

const HorizontalCards = (props: IHorizontalCardsProps): JSX.Element => {
  const { banners, showAs, tabButtonStyle, blockName } = props;

  const showAsTabs = showAs === EHorizontalCardsShowAs.Tabs;
  const showAsStack = showAs === EHorizontalCardsShowAs.Stack;
  const showAsScroll = showAs === EHorizontalCardsShowAs.Scroll;

  const buttonsWrapRef = useRef<HTMLDivElement>(null);

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  const getAllTabsButtonsWidthsArray = useMemo(() => {
    if (!buttonsWrapRef.current) {
      return [];
    }
    const buttonsContainer = buttonsWrapRef.current.children[0];

    return Array.from(buttonsContainer.children)?.map(
      (child: Element) =>
        child.getBoundingClientRect().width + BUTTONS_CONTAINER_GAP_SIZE_PX,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonsWrapRef.current]);

  useEffect(() => {
    if (buttonsWrapRef.current) {
      const allButtonsWidthArray = getAllTabsButtonsWidthsArray || [];

      const left = allButtonsWidthArray.reduce((accum, currentValue, index) => {
        if (index >= currentBannerIndex) {
          return accum;
        }
        return (accum += currentValue);
      }, 0);

      buttonsWrapRef.current.scrollLeft = left;
    }
  }, [currentBannerIndex, getAllTabsButtonsWidthsArray]);

  return (
    <section
      className={clsx(
        'grid',
        showAsStack && 'gap-4 2xl:gap-8',
        showAsTabs && 'gap-6',
      )}
    >
      {showAsTabs &&
        tabButtonStyle === EHorizontalCardsTabButtonStyle.Primary && (
          <div
            ref={buttonsWrapRef}
            className='scrollbar-hidden flex gap-x-6 gap-y-2 overflow-auto scroll-smooth px-4 lg:container lg:flex-wrap lg:justify-center 2xl:gap-x-10 2xl:gap-y-4'
          >
            {banners.map((card, index) => (
              <button
                key={index}
                className={clsx(
                  'flex items-center gap-2 whitespace-nowrap text-lg font-medium leading-normal transition',
                  currentBannerIndex === index &&
                    'text-brand-500 hover:text-brand-550',
                  currentBannerIndex !== index &&
                    'text-control-600 hover:text-brand-500',
                )}
                onClick={() => setCurrentBannerIndex(index)}
                data-ga-id={getGaId(card.title, blockName, 'tab')}
              >
                <span className='flex aspect-square h-5 w-5 items-center justify-center rounded-[6px] bg-current text-xs leading-[1]'>
                  <span className='text-white'>{index + 1}</span>
                </span>
                {card.title}
              </button>
            ))}
          </div>
        )}
      {showAsTabs &&
        tabButtonStyle === EHorizontalCardsTabButtonStyle.Secondary && (
          <div
            ref={buttonsWrapRef}
            className='scrollbar-hidden mx-auto flex max-w-[100vw] overflow-auto scroll-smooth px-4 2xl:max-w-[54rem] '
          >
            <div className='flex min-w-fit gap-2 2xl:flex-wrap 2xl:justify-center'>
              {banners.map((card, index) => (
                <Button
                  key={index}
                  theme={
                    currentBannerIndex === index
                      ? EButtonTheme.Primary
                      : EButtonTheme.Secondary
                  }
                  size={EButtonSize.Small}
                  onClick={() => setCurrentBannerIndex(index)}
                  className='whitespace-nowrap'
                  data-ga-id={getGaId(card.title, blockName, 'tab')}
                >
                  {card.title}
                </Button>
              ))}
            </div>
          </div>
        )}
      {showAsTabs && (
        <Container isPadding={false}>
          <div className='mx-auto lg:max-w-5xl'>
            <HorizontalCard {...banners[currentBannerIndex]} />
          </div>
        </Container>
      )}
      {showAsStack && (
        <Container>
          <div className='mx-auto lg:max-w-5xl'>
            {banners.map((banner, i) => (
              <div className='mb-4 last:mb-0'>
                <HorizontalCard key={i} {...banner} />
              </div>
            ))}
          </div>
        </Container>
      )}
      {showAsScroll && (
        <div className='scrollbar-hidden overflow-auto scroll-smooth'>
          <Container>
            <div className='mx-auto lg:max-w-5xl'>
              <div className='flex gap-4'>
                {banners.map((banner, i) => (
                  <div key={i} className='min-w-[95%] lg:min-w-full'>
                    <HorizontalCard {...banner} />
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>
      )}
    </section>
  );
};

export default HorizontalCards;
