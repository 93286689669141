import { useRouter } from 'next/router';
import React from 'react';

import { ReactComponent as IconLink } from '../../../../public/icons/components/share/Icon-Link.svg';
import { ReactComponent as IconShare } from '../../../../public/icons/components/share/Icon-Share.svg';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { ESocialPlatformType, ShareLink } from '../ShareLink';

export interface IShareButtonProps {
  /*
   * Pass url that should be shared on social network
   * Default will be used if not passed
   */
  url?: string;

  /*
   * Text displayed on button
   */
  shareText: string;

  /*
   * Some sharing services require text provided in addition to url
   * Expected text is a Title of the shared url
   */
  metaText?: string;
}

async function handleCopyUrlToClipboard(url: string): Promise<void> {
  await navigator.clipboard.writeText(url);
}

const ShareButton = (props: IShareButtonProps): JSX.Element => {
  const { url, shareText, metaText } = props;

  const router = useRouter();

  const HOST =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : process.env.NEXT_PUBLIC_APP_HOST;

  const defaultCurrentUrl = `${HOST}${router.asPath}`;

  const finalUrl = url || defaultCurrentUrl;

  return (
    <div className='flex items-stretch'>
      <div className='flex flex-1 items-start'>
        <Popover placement='bottom-start'>
          <PopoverTrigger className='group flex items-center gap-2 text-sm font-medium text-control-600 transition hover:text-control-650'>
            {shareText}
            <IconShare className='aspect-square h-4 w-4 fill-control-600 transition group-hover:fill-control-650' />
          </PopoverTrigger>
          <PopoverContent className='flex max-w-[12rem] gap-4 rounded-xl bg-surface-50-tooltip/30 p-4 text-xs leading-loose text-interface-1000 shadow-md backdrop-blur'>
            <ShareLink
              currentUrl={finalUrl}
              socialPlatform={ESocialPlatformType.Facebook}
            />
            <ShareLink
              title={metaText}
              currentUrl={finalUrl}
              socialPlatform={ESocialPlatformType.Linkedin}
            />
            <ShareLink
              title={metaText}
              currentUrl={finalUrl}
              socialPlatform={ESocialPlatformType.Twitter}
            />
            <ShareLink
              title={metaText}
              currentUrl={finalUrl}
              socialPlatform={ESocialPlatformType.Telegram}
            />
            <div
              className='group cursor-pointer'
              onClick={() => handleCopyUrlToClipboard(finalUrl)}
            >
              <IconLink className='aspect-square h-4 w-4 fill-control-600 transition group-hover:fill-control-650' />
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default ShareButton;
