import React from 'react';

import Tab from '../Tab/Tab';
import { ETabSize, ETabTheme } from '../Tab/Tab.constants';

export interface ITabsProps {
  value: string;
  options: { label: string; value: string }[];
  size: ETabSize;
  theme: ETabTheme;
  onChange: (value: string) => void;
}

const Tabs = (props: ITabsProps): JSX.Element => {
  const { options, onChange, theme, size, value } = props;

  return (
    <div
      className='scrollbar-hidden mx-auto flex snap-x snap-mandatory gap-3 overflow-x-auto px-4 pe-4 ps-4'
      role='tablist'
      aria-orientation='horizontal'
    >
      {options.map((option, index) => (
        <Tab
          onClick={onChange}
          key={index}
          theme={theme}
          size={size}
          value={value}
          label={option.label}
          isActive={option.value === value}
        />
      ))}
    </div>
  );
};

export default Tabs;
