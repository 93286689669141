import React, { useState } from 'react';

import { Video, Container } from '../../../../src/index';
import { VideoCarousel } from '../../atoms/VideoCarousel';

import type { VideoCollection } from '@shared/master-types';

export type TVideoPlayerList = {
  selectedVideos: VideoCollection[];
  listTitle: string;
};

export const VideoPlayerList: React.FC<TVideoPlayerList> = ({
  selectedVideos,
  listTitle,
}) => {
  const [videos, setVideos] = useState(selectedVideos.slice(1));
  const [currentVideo, setCurrentVideo] = useState(selectedVideos[0]);
  const [currentVideoHasCover, setCurrentVideoHasCover] = useState(true);
  const clickHandler = (video: VideoCollection): void => {
    if (currentVideo) {
      const filteredVideos = videos.filter(item => item.id !== video.id);
      setVideos([currentVideo, ...filteredVideos]);
    }
    setCurrentVideo(video);
    setCurrentVideoHasCover(false);
  };
  return (
    <Container>
      <div className='flex flex-wrap items-stretch justify-center gap-4 md:flex-nowrap'>
        {currentVideo && (
          <Video
            hasCover={currentVideoHasCover}
            className='basis-full md:basis-1/2 lg:basis-2/3'
            video={currentVideo}
          />
        )}
        {!!videos?.length && (
          <div className='basis-full md:basis-1/2 lg:basis-1/3'>
            <VideoCarousel
              videos={videos}
              title={listTitle}
              videoItemClickHandler={clickHandler}
            />
          </div>
        )}
      </div>
    </Container>
  );
};
