import Link from 'next/link';
import React from 'react';

export interface IArticleContentProps {
  items: { id: string; title: string }[];
}

const ArticleContent = (props: IArticleContentProps): JSX.Element => {
  const { items } = props;

  return (
    <nav>
      <ul className='grid pr-2'>
        {items.map(item => (
          <li key={item.id}>
            <Link
              href={`#${item.id}`}
              className='block rounded-xl px-3 py-2 text-sm leading-loose text-interface-800 transition hover:bg-control-100'
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ArticleContent;
