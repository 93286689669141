import Link from 'next/link';
import React from 'react';

import { TActionLink } from '../../../types/actions';

export interface IEventInfoProps {
  title?: string;
  dateTitle?: string;
  locationTitle?: string;
  location: string;
  startDate: string;
  endDate: string;
  registerLink?: TActionLink;
}

const EventInfo = (props: IEventInfoProps): JSX.Element => {
  const {
    title,
    dateTitle,
    locationTitle,
    location,
    startDate,
    endDate,
    registerLink,
  } = props;

  return (
    <div>
      {title && (
        <h3 className='mb-4 text-xl font-semibold text-interface-1000'>
          {title}
        </h3>
      )}
      <div className='rounded-3xl bg-surface-100 p-4'>
        {dateTitle && (
          <h4 className='mb-2 text-xs font-medium text-interface-500'>
            {dateTitle}
          </h4>
        )}
        <p className='mb-4 text-base font-medium leading-loose text-interface-1000'>
          {`${startDate} - ${endDate}`}
        </p>
        {locationTitle && (
          <h4 className='mb-2 text-xs font-medium text-interface-500'>
            {locationTitle}
          </h4>
        )}
        {location && (
          <p className='mb-0 text-base font-medium text-interface-1000'>
            {location}
          </p>
        )}
        {registerLink && (
          <Link
            {...registerLink.props}
            className='mt-6 block rounded-xl bg-brand-1000/80 p-4 text-center text-base font-medium text-brand-50/90 transition hover:bg-brand-1000'
          />
        )}
      </div>
    </div>
  );
};

export default EventInfo;
