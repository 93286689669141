var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"XLJpciOpUlsWnfeFEOLqj"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

declare const window: Window &
  typeof globalThis & {
    NEXT_PUBLIC_SENTRY_DSN_URL: string | undefined;
  };

const SENTRY_DSN_URL: string =
  window.NEXT_PUBLIC_SENTRY_DSN_URL ||
  process.env.NEXT_PUBLIC_SENTRY_DSN_URL ||
  '';

Sentry.init({
  dsn: SENTRY_DSN_URL,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
});
