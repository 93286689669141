import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';

export type TTagInputProps = React.DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  placeholder: string;
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  dynamicWidth?: boolean;
  value?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const TagInput = forwardRef<HTMLInputElement, TTagInputProps>((props, ref) => {
  const {
    id,
    IconComponent,
    placeholder,
    value,
    className,
    dynamicWidth,
    onChange,
    onBlur,
    onFocus,
  } = props;

  const customStyle = dynamicWidth
    ? {
        minWidth: `calc(${Math.min(placeholder.length, 25)}ch + 32px)`,
        width: `calc(${value?.length}ch + 32px)`,
      }
    : undefined;

  return (
    <div className='relative max-w-full'>
      <input
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        type='text'
        id={id}
        ref={ref}
        placeholder={placeholder}
        style={customStyle}
        className={clsx(
          className,
          'leading-4 min-w-60 peer box-border h-8 max-w-full text-ellipsis rounded-lg border-0 bg-surface-100-input py-2 pl-10 pr-4 text-sm font-medium text-white caret-brand-500 outline-none transition placeholder:text-interface-500 focus:bg-surface-100-input focus:text-interface-800 focus:placeholder:text-interface-800 [&:not(:focus):not(:placeholder-shown)]:bg-brand-500',
        )}
      />
      <IconComponent
        className={clsx(
          'fill:interface-500 absolute top-0 my-2 ml-4 aspect-square transition peer-[:not(:placeholder-shown):not(:focus)]:[&>*]:fill-white peer-focus:[&>path]:fill-interface-800',
        )}
      />
    </div>
  );
});

TagInput.displayName = 'TagInput';

export default TagInput;
