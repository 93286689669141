import Link from 'next/link';
import { ComponentProps } from 'react';

import { Button } from '../components/atoms/Button';

export enum EActionType {
  ButtonType = 'button',
  LinkType = 'link',
  LinksGroupType = 'links-group',
}

export type TAction =
  | {
      type: EActionType.ButtonType;
      text?: string;
      props: ComponentProps<typeof Button> & { description?: string };
    }
  | TActionLink
  | {
      type: EActionType.LinksGroupType;
      label?: string;
      actions?: TAction[];
    };

export type TActionLink = {
  type: EActionType.LinkType;
  text?: string;
  props: ComponentProps<typeof Link> & { description?: string };
};
