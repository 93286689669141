import { Footer, Jurisdiction, Locale } from '@shared/master-types';
import dynamic from 'next/dynamic';

const FooterOneColumnConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FooterOneColumnConnected" */ '../../layout/FooterOneColumnConnected'
    ),
);
const FooterWithSitemapConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FooterOneColumnConnected" */ '../../layout/FooterWithSitemapConnected'
    ),
);

export interface IFooterParserProps {
  footers?: Footer | string;
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
}

const FooterParser = (props: IFooterParserProps): JSX.Element | null => {
  const {
    footers: footerDocs,
    locales,
    jurisdictionList,
    jurisdictionItem,
  } = props;
  if (typeof footerDocs !== 'string') {
    const footer = footerDocs?.footer && footerDocs?.footer[0];

    switch (footer?.blockType) {
      case 'footer-one-column':
        return (
          <FooterOneColumnConnected
            key={footer.id}
            locales={locales}
            jurisdictionList={jurisdictionList}
            jurisdictionItem={jurisdictionItem}
            {...footer}
          />
        );
      case 'footer-with-sitemap':
        return (
          <FooterWithSitemapConnected
            key={footer.id}
            locales={locales}
            jurisdictionList={jurisdictionList}
            jurisdictionItem={jurisdictionItem}
            {...footer}
          />
        );
      default:
        return null;
    }
  }
  return null;
};

export default FooterParser;
