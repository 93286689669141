import React, { ReactNode, Component } from 'react';

export interface IErrorBoundaryState {
  hasError: boolean;
  error: string | null;
}

export interface IErrorBoundaryProps {
  fallback?: JSX.Element;
  children?: React.ReactNode;
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  // @ts-expect-error TODO: check linter?
  public state = { hasError: false, error: null };
  public static getDerivedStateFromError({
    message,
  }: Error): IErrorBoundaryState {
    return {
      hasError: true,
      error: message,
    };
  }
  // @ts-expect-error TODO: check linter?
  public render(): ReactNode {
    const { hasError } = this.state;
    const { children, fallback = null } = this.props;

    if (hasError) {
      return fallback;
    }
    return children;
  }
}

export default ErrorBoundary;
