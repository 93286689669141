import React from 'react';

import { PricingCol, TAction } from '../../../../src/index';
import { TPricingColProps } from '../../atoms/PricingCol';

export type TPricingPlanProps = {
  pricingPlan: TPricingColProps[];
  actions?: TAction[];
};

const PricingPlan = (props: TPricingPlanProps): JSX.Element => {
  const { pricingPlan, actions } = props;

  return (
    <div className='scrollbar-hidden flex justify-start gap-4 overflow-auto scroll-smooth px-4 2xl:justify-center 2xl:overflow-hidden'>
      {pricingPlan.map(pricing => (
        <PricingCol
          key={pricing.tariff}
          actions={actions}
          tip={pricing?.tip}
          tariff={pricing.tariff}
          price={pricing.price}
          priceInterval={pricing.priceInterval}
          points={pricing.points}
        />
      ))}
    </div>
  );
};

export default PricingPlan;
