import clsx from 'clsx';
import React from 'react';

import { RichText, TRichTextContent } from '../RichText';

export type TListProps = {
  listTitle?: string;
  list?: TRichTextContent;
  isOpenInNewTab?: boolean;
};

export const List: React.FC<TListProps> = props => {
  const { listTitle, list, isOpenInNewTab } = props;

  return (
    <div
      className={clsx(
        'grid flex-col gap-2 break-words py-4 text-interface-800',
        'prose-h3:text-base prose-h3:font-semibold prose-h3:text-interface-1000',
        'marker:font-medium marker:text-brand-500',
        'prose-ul:grid prose-ul:max-w-lg prose-ul:list-disc prose-ul:gap-2 prose-ul:pl-6',
        'prose-ol:grid prose-ol:max-w-lg prose-ol:list-decimal prose-ol:gap-2 prose-ol:pl-6',
      )}
    >
      {listTitle && <h3>{listTitle}</h3>}
      <RichText isOpenInNewTab={isOpenInNewTab} content={list} />
    </div>
  );
};
