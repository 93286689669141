import { EActionType, TAction } from '@front/shared/ds';
import {
  checkLocaleExist,
  getJurisdictionPath,
  checkJurisdictionExist,
} from '@front/shared/utils';
import { Locale, Jurisdiction } from '@shared/master-types';

export interface ICheckActionProps {
  jurisdictionList?: Jurisdiction[];
  localeList?: Locale[];
  item?: TAction;
  slug?: string;
}

export default function checkTAction(props: ICheckActionProps): TAction | void {
  const { jurisdictionList, localeList, item, slug } = props;
  if (item?.type === EActionType.LinkType) {
    const oldHref = item.props.href + '';

    if (!oldHref.startsWith('http')) {
      const newJurisdiction = oldHref.match(/(#(.*?)\/)/m)?.[2];
      const jurisdiction = newJurisdiction ?? slug ?? '';

      const pureLink = oldHref.replace(/(#(.*?)\/)/m, '#');
      const hash = pureLink.split('#')[1] ?? '';

      let isLocale = true;
      const formatedPureLink = pureLink
        .split('#')[0]
        .split('/')
        .map((partOfLink, i, array) => {
          if (i === 1) {
            const isNotEn = partOfLink !== 'en';
            if (
              localeList?.length &&
              !checkLocaleExist({ localeList, locale: partOfLink })
            ) {
              isLocale = false;
              return getJurisdictionPath({
                jurisdictionList,
                jurisdiction,
                partOfLink,
              });
            } else if (
              i === array.length - 1 &&
              checkJurisdictionExist({ jurisdictionList, jurisdiction })
            ) {
              return `${isNotEn ? partOfLink + '/' : ''}${jurisdiction}`;
            }
            return isNotEn ? partOfLink : '';
          } else if (isLocale && i === 2) {
            return getJurisdictionPath({
              jurisdictionList,
              jurisdiction,
              partOfLink,
            });
          }
          return partOfLink;
        })
        .join('/')
        .replace('//', '/');

      return {
        ...item,
        props: {
          ...item.props,
          href: hash ? `${formatedPureLink}#${hash}` : formatedPureLink,
        },
      };
    }
  }
}
