import {
  BoldText,
  EVideoSize,
  Faq,
  ImageBlock,
  List,
  Paragraph,
  Quote,
  Title,
  Video,
} from '@front/shared/ds';
import { BlogArticle, BlogEvent } from '@shared/master-types';
import React from 'react';

export type TSingleContentConnectedProps = (BlogArticle | BlogEvent) & {
  locale: string;
  jurisdiction: string;
  isOpenInNewTab?: boolean;
};

export const SingleContentConnected = (
  props: TSingleContentConnectedProps,
): JSX.Element => {
  const { rows, locale, jurisdiction, isOpenInNewTab } = props;

  const content = rows?.map(row => {
    switch (row.blockType) {
      case 'title':
        return (
          <Title
            isOpenInNewTab={isOpenInNewTab}
            key={row.id}
            title={row.title}
          />
        );

      case 'paragraph':
        return (
          <Paragraph
            isOpenInNewTab={isOpenInNewTab}
            key={row.id}
            paragraph={row.paragraph}
          />
        );

      case 'boldText':
        return (
          <BoldText
            isOpenInNewTab={isOpenInNewTab}
            key={row.id}
            boldText={row.boldText}
          />
        );

      case 'list':
        return (
          <List
            isOpenInNewTab={isOpenInNewTab}
            key={row.id}
            list={row.list}
            listTitle={row.listTitle}
          />
        );

      case 'quote':
        return (
          <Quote
            isOpenInNewTab={isOpenInNewTab}
            key={row.id}
            locale={locale}
            jurisdiction={jurisdiction}
            {...row}
            link={row.link}
          />
        );

      case 'imageBlock':
        return (
          <ImageBlock
            isOpenInNewTab={isOpenInNewTab}
            key={row.id}
            image={row.image}
            text={row.text}
          />
        );

      case 'video':
        return (
          <Video
            key={row.id}
            video={row.video}
            text={row.text}
            className='py-4'
            size={EVideoSize.Xl}
          />
        );

      case 'faq':
        return (
          <Faq
            isOpenInNewTab={isOpenInNewTab}
            key={row.id}
            faqTitle={row.faqTitle}
            items={row.items}
          />
        );

      default:
        return <></>;
    }
  });

  return <>{content}</>;
};
