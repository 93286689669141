import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

import { buildDateFormat, EActionType, TAction } from '../../../../src/index';

export interface IEventThumbnailProps {
  cover: ImageProps | null;
  title: string;
  excerpt?: string;
  startDate: string;
  endDate: string;
  location: string;
  id?: string;
  url: TAction;
  locale: string;
}

const EventThumbnail = (props: IEventThumbnailProps): JSX.Element => {
  const {
    title,
    cover,
    startDate,
    endDate,
    excerpt,
    locale,
    location,
    id,
    url,
  } = props;

  const startDatePrepared = new Date(startDate);
  const endDatePrepared = new Date(endDate);

  const formattedStartDate = buildDateFormat(startDatePrepared, locale);

  const formattedEndDate = buildDateFormat(endDatePrepared, locale);

  const eventDate = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    <article>
      {url.type === EActionType.LinkType && (
        <Link {...url.props}>
          <div
            key={id}
            className='mb-6 flex w-full flex-col gap-4 last:mb-0 lg:mb-0 lg:flex-row 2xl:h-[14.5rem]'
          >
            {cover && (
              <div className='w-full  overflow-hidden rounded-3xl lg:max-w-[25.5rem]'>
                <Image
                  {...cover}
                  className='h-[12.95rem] w-full shrink-0 object-cover object-center transition-transform duration-500  hover:scale-110 lg:h-full   '
                  width={302}
                  height={190}
                />
              </div>
            )}
            <div className='w-full rounded-3xl bg-surface-100 p-8'>
              {title && (
                <h3 className='mb-3 line-clamp-3 text-2xl font-semibold leading-relaxed text-interface-1000 lg:line-clamp-2 2xl:text-4xl'>
                  {title}
                </h3>
              )}
              {excerpt && (
                <p className='line-clamp-6 text-base font-normal text-interface-800 lg:line-clamp-4 2xl:line-clamp-2 '>
                  {excerpt}
                </p>
              )}
              <div className='mt-4 flex flex-col items-start gap-2 lg:flex-row lg:flex-wrap lg:items-center'>
                {location && (
                  <p className='rounded-lg bg-brand-500 px-3 py-1 text-xs font-normal text-brand-50/90'>
                    {location}
                  </p>
                )}
                {eventDate && (
                  <p className='text-xs font-normal text-interface-800'>
                    {eventDate}
                  </p>
                )}
              </div>
            </div>
          </div>
        </Link>
      )}
    </article>
  );
};

export default EventThumbnail;
