import { SectionError } from '@front/shared/ds';
import { checkLocaleExist } from '@front/shared/utils';
import { useRouter } from 'next/router';
import { NextPageContext } from 'next/types';
import React from 'react';

import {
  useGetFooterQuery,
  useGetHeaderQuery,
  useGetLocalesQuery,
  useGetSettingsQuery,
} from '../../../features';
import { FooterParser, HeaderParser } from '../../layout';

export type TCustomErrorPageProps = {
  statusCode: number;
};

export const CustomErrorPage = (props: TCustomErrorPageProps): JSX.Element => {
  const { statusCode } = props;

  const pathPattern = useRouter().asPath.split('/')[1];

  const { data: localesObject } = useGetLocalesQuery();
  const locales = localesObject?.docs;

  const isLocale = checkLocaleExist({
    locale: pathPattern,
    localeList: locales,
  });
  const locale = isLocale ? pathPattern : 'en';

  const { data: settings } = useGetSettingsQuery({ locale });
  const { data: headers } = useGetHeaderQuery({ locale });
  const { data: footers } = useGetFooterQuery({ locale });

  const { errorPage } = settings ?? {};
  const header = headers?.docs?.[0];
  const footer = footers?.docs?.[0];

  return (
    <>
      {header && <HeaderParser headers={header} locales={locales} />}
      <SectionError
        statusCode={statusCode}
        darkTheme={errorPage?.isDarkErrorPage}
        errorText={statusCode === 404 ? errorPage?.errorText : undefined}
        buttonText={errorPage?.buttonText}
      />
      {footer && <FooterParser footers={footer} locales={locales} />}
    </>
  );
};

CustomErrorPage.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};
