import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React from 'react';

import {
  ECardWrapperBackground,
  ECardWrapperLayout,
} from './CardWrapper.constants';
import { Card, ECardTextColor, ETitleSize, ICardProps } from '../Card';

export interface ICardWrapperProps extends ICardProps {
  /**
   * Сard display type.
   */
  size?: ECardWrapperLayout;

  /**
   * Main image for the card.
   */
  image?: ImageProps | null;

  /**
   * If true swap image and content (mobile resolution image only below). Used only on "BannerCard" and "BannerCardWithBg".
   */
  swapImageSide?: boolean;
  /**
   * Background.
   */
  background?: ECardWrapperBackground;
}

/**
 * A fundamental basic component used as a building block for constructing more complex components.
 * It's a wrapper for the Card component and has various options for displaying the content.
 *
 * @param props - Card props.
 * @returns JSX.Element.
 */
const CardWrapper = (props: ICardWrapperProps): JSX.Element => {
  const {
    size = ECardWrapperLayout.DefaultCard,
    image = null,
    background = ECardWrapperBackground.Light,
    ...rest
  } = props;

  let cardTextColor = ECardTextColor.Dark;
  let cardBackground = ' bg-surface-100';
  if (background === ECardWrapperBackground.Dark) {
    cardTextColor = ECardTextColor.Light;
    cardBackground = ' bg-control-1000';
  } else if (background === ECardWrapperBackground.White) {
    cardBackground = ' bg-surface-50';
  } else if (background === ECardWrapperBackground.Brand) {
    cardBackground = ' bg-brand-500';
    cardTextColor = ECardTextColor.Light;
  }

  return (
    <div className='h-full flex-1'>
      {size === ECardWrapperLayout.BrandCard && (
        <div
          className={clsx(
            'flex flex-col justify-between gap-4 rounded-[1.25rem] p-8 lg:flex-row lg:items-center 2xl:h-full' +
              cardBackground,
          )}
        >
          <div className='flex-1'>
            <Card
              titleSize={ETitleSize.UnchangingMedium}
              cardTextColor={cardTextColor}
              {...rest}
            />
          </div>
          {image && (
            <Image
              {...image}
              className='h-72 w-auto flex-1 rounded-[1.25rem] object-cover lg:w-[17.5rem] lg:flex-none'
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CardWrapper;
