import { DEFAULT_LOCALE } from '@front/shared';
import {
  EActionType,
  TAction,
  toLinkProps,
  toImageProps,
  EButtonVariant,
} from '@front/shared/ds';
import { Header } from '@shared/master-types';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useDebugValue, useMemo } from 'react';

import { useAppDispatch } from '../../store/store.hooks';
import { setCurrentModalId } from '../modals/modals.state';

type TMenuItems = NonNullable<Header['header']>[number]['menuItems'];
type TMenuActions = NonNullable<Header['header']>[number]['menuActions'];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useActions = (
  actions: TMenuItems | TMenuActions | undefined = [],
): TAction[] => {
  const dispatch = useAppDispatch();

  const router = useRouter();

  const handleActionType = useCallback(
    (action: TMenuItems[number]['action']): TAction => {
      if (action?.type === 'button') {
        return {
          type: EActionType.ButtonType,
          text: action.button?.text,
          props: {
            variant: action.variant
              ? (action.variant as EButtonVariant)
              : undefined,
            children: (
              <span className='flex justify-center gap-2 align-middle'>
                <span>{action.button?.text}</span>
                {action?.icon &&
                  typeof action.icon !== 'string' &&
                  action.icon.url &&
                  action.icon.alt && (
                    <Image
                      src={action.icon.url}
                      alt={action.icon.alt}
                      className='h-4 w-4'
                      {...toImageProps(action.icon)}
                    />
                  )}
              </span>
            ),
            onClick: () => {
              if (
                action.button?.action === 'modal' &&
                action.button?.modal?.reference.value
              ) {
                return dispatch(
                  setCurrentModalId({
                    modalId: String(action.button?.modal?.reference.value),
                  }),
                );
              }

              return undefined;
            },
          },
        };
      }

      if (action?.type === 'link') {
        return {
          type: EActionType.LinkType,
          text: action.link?.text,
          props: {
            children: (
              <span className='flex justify-start gap-2 align-middle'>
                <span>{action.link?.text}</span>
                {action?.icon &&
                  typeof action.icon !== 'string' &&
                  action.icon.url &&
                  action.icon.alt && (
                    <Image
                      src={action.icon.url}
                      alt={action.icon.alt}
                      className='h-4 w-4'
                      {...toImageProps(action.icon)}
                    />
                  )}
              </span>
            ),
            ...toLinkProps(
              String(router.query.locale || DEFAULT_LOCALE),
              action.link,
            ),
          },
        };
      }

      throw new Error(`Unsupported action type ${action?.type} detected`);
    },
    [dispatch, router.query.locale],
  );

  const nextActions = useMemo<TAction[]>(() => {
    return actions.map(actionItem => {
      switch (actionItem.type) {
        case 'actions-group':
          return {
            type: EActionType.LinksGroupType,
            label: actionItem?.actionsGroup?.label,
            actions: actionItem?.actionsGroup?.actions.map(actionGroupItem =>
              handleActionType(actionGroupItem),
            ),
          };
        case 'action':
          return handleActionType(actionItem.action);
        case 'button':
        case 'link':
        default:
          return handleActionType(actionItem);
      }
    });
  }, [actions, handleActionType]);

  useDebugValue(nextActions);

  return nextActions;
};
