import { BaseApp, reportWebVitals, TBaseAppProps } from '@front/master';
import { Manrope } from 'next/font/google';

import '../styles/globals.css';

const manropeFont = Manrope({ subsets: ['latin'] });

function App(props: TBaseAppProps): JSX.Element {
  return <BaseApp className={manropeFont.className} {...props} />;
}

export { reportWebVitals };

export default App;
