import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

import { ReactComponent as IconAvatarStub } from '../../../../public/icons/icon-avatar-stub.svg';
import getSocialIcon from '../../../utils/getSocialIcon';
import { RichText, TRichTextContent } from '../RichText';

export type TSocialItem = {
  type:
    | 'telegram'
    | 'linkedIn'
    | 'facebook'
    | 'youtube'
    | 'instagram'
    | 'twitter';
  link: string;
};

export type TAuthorProps = {
  name: string;
  avatar?: ImageProps | null;
  prefix?: string;
  href?: string;
  excerpt?: TRichTextContent;
  viewMore?: string;
  socials?: TSocialItem[];
};

const Author = (props: TAuthorProps): JSX.Element => {
  const {
    avatar,
    name,
    prefix = 'By',
    href,
    excerpt,
    viewMore,
    socials = [],
  } = props;

  const tooltip =
    excerpt || socials.length || (href && viewMore) ? (
      <div className='invisible absolute z-10 pt-3 group-hover:visible'>
        <div className='flex w-72 flex-col gap-3 rounded-xl bg-surface-50-tooltip bg-opacity-30 p-4 shadow-lg backdrop-blur-xl'>
          <RichText
            className='font-regular text line-clamp-3 text-xs leading-extra-loose text-interface-1000/80'
            content={excerpt}
          />
          <div className='flex items-center justify-between'>
            {href && viewMore && (
              <Link
                className='text-sm font-medium leading-normal text-brand-500'
                href={href}
              >
                {viewMore}
              </Link>
            )}
            {socials.length !== 0 && (
              <div className='flex gap-3 self-start text-control-600 lg:self-end'>
                {socials.map(s => (
                  <a
                    key={s.type}
                    href={s.link}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {getSocialIcon(
                      s.type,
                      'aspect-square h-4 w-4 object-contain object-center',
                    )}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    ) : null;

  return href ? (
    <div className='group relative'>
      <Link href={href}>
        <div className='flex items-center gap-2 text-sm leading-loose text-interface-500'>
          {avatar ? (
            <Image
              {...avatar}
              className='h-6 w-6 rounded-[50%] bg-control-100'
            />
          ) : (
            <IconAvatarStub className='h-6 w-6 rounded-[50%] bg-control-100' />
          )}
          <span className='flex items-center text-xs '>
            <span className='text-interface-500'>{prefix}</span>&nbsp;
            <span className='text-control-950'>{name}</span>
          </span>
        </div>
      </Link>
      {tooltip}
    </div>
  ) : (
    <div className='group relative'>
      <div className='flex items-center gap-2 text-sm leading-loose text-interface-500'>
        {avatar ? (
          <Image {...avatar} className='h-6 w-6 rounded-[50%] bg-control-100' />
        ) : (
          <IconAvatarStub className='h-6 w-6 rounded-[50%] bg-control-100' />
        )}
        <span className='flex items-center text-xs '>
          <span className='text-interface-500'>{prefix}</span>&nbsp;
          <span className='text-control-950'>{name}</span>
        </span>
      </div>
      {tooltip}
    </div>
  );
};

export default Author;
