import format from 'date-fns/format';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

import { EActionType, TAction } from '../../../types';
import { Tag, ETagSize, ETagVariant, ETagTheme } from '../Tag';

interface ITag {
  children: React.ReactNode;
  id: string;
  categorising?: string;
}

export interface IArticleThumbnailProps {
  cover: ImageProps | null;
  title: string;
  url: TAction;
  publishedAt: string;
  tags?: ITag[];
  authors?: { name: string }[];
  aspectRatio?: boolean;
}

const ArticleThumbnail = (props: IArticleThumbnailProps): JSX.Element => {
  const { cover, title, url, tags, authors, publishedAt, aspectRatio } = props;

  return (
    <article
      className='flex flex-col overflow-hidden'
      itemScope
      itemType='http://schema.org/Article'
    >
      {url.type === EActionType.LinkType && (
        <Link {...url.props}>
          {cover &&
            (aspectRatio ? (
              <div className='relative aspect-video max-h-[21.9rem] w-full'>
                <div className='absolute top-0 h-full w-full overflow-hidden rounded-2xl'>
                  <Image
                    {...cover}
                    className='h-full w-full transform object-cover transition-transform duration-500 hover:scale-110'
                    itemProp='image'
                    width={302}
                    height={190}
                  />
                </div>
              </div>
            ) : (
              <div className='overflow-hidden rounded-2xl'>
                <Image
                  {...cover}
                  className='h-[12.125rem] w-full transform object-cover transition-transform duration-500   hover:scale-110 2xl:h-[10.625rem]'
                  itemProp='image'
                  width={302}
                  height={190}
                />
              </div>
            ))}
          <h2
            className='mt-5 line-clamp-2 text-2xl font-semibold leading-relaxed text-interface-1000'
            itemProp='headline'
          >
            {title}
          </h2>
        </Link>
      )}
      <div className='mt-4 flex flex-wrap items-center gap-3'>
        {tags?.map(({ id, children, ...tag }) => {
          const tagWrapper = (
            <Tag
              key={id}
              {...tag}
              variant={ETagVariant.Contained}
              theme={ETagTheme.Secondary}
              size={ETagSize.Small}
              itemProp='about'
              itemScope
              itemType='http://schema.org/Thing'
            >
              <span itemProp='name'>{children}</span>
            </Tag>
          );

          return tag.categorising ? (
            <Link key={id} href={tag.categorising}>
              {tagWrapper}
            </Link>
          ) : (
            tagWrapper
          );
        })}
        <time
          dateTime={publishedAt}
          className='text-xs font-medium leading-relaxed text-interface-800'
          itemProp='datePublished'
        >
          {format(new Date(publishedAt), 'dd.MM.yyyy')}
        </time>
      </div>
      <div hidden aria-hidden='true'>
        {authors?.map((author, index) => (
          <div
            key={index}
            itemProp='author'
            itemScope
            itemType='http://schema.org/Person'
          >
            <span itemProp='name'>{author.name}</span>
          </div>
        ))}
      </div>
    </article>
  );
};
export default ArticleThumbnail;
