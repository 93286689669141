import { Media } from '@shared/master-types';
import React from 'react';

import { Container } from '../../sections/Container';

export type TDocumentsListProps = {
  buttonLabel: string;
  documents?: { title: string; document: string | Media }[];
};

const DocumentsList = (props: TDocumentsListProps): JSX.Element => {
  const { buttonLabel, documents } = props;

  return (
    <Container>
      <div className='mx-auto flex max-w-[38.75rem] flex-col'>
        {documents?.map(document => {
          if (typeof document.document === 'string' || !document.document.url) {
            throw new Error(
              'Wrong document provided, expected object but got string. Url is required',
            );
          }
          return (
            <div className='mt-5 flex flex-col first:mt-0' key={document.title}>
              <h3 className='text-xl font-semibold leading-normal text-interface-1000'>
                {document.title}
              </h3>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={document.document.url}
                className='py-2 text-base text-brand-500'
              >
                {buttonLabel}
              </a>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default DocumentsList;
