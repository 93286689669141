import clsx from 'clsx';
import React from 'react';

import { EFeaturesGridShowAs } from './FeaturesGrid.constants';
import useSliderMouseEvents from '../../../features/slider/useSliderMouseEvents.hook';
import FeatureTile from '../../atoms/FeatureTile';
import { Container } from '../../sections/Container';

export interface IFeaturesGridProps {
  /**
   * Array of props for the FeatureTile component.
   */
  features: React.ComponentPropsWithoutRef<typeof FeatureTile>[];
  showAs?: EFeaturesGridShowAs | string;
}

const FeaturesGrid = (props: IFeaturesGridProps): JSX.Element => {
  const { features, showAs = EFeaturesGridShowAs.Grid } = props;
  const slideHandlers = useSliderMouseEvents(features.length);

  return showAs === EFeaturesGridShowAs.Row ? (
    <section className='scrollbar-hidden overscroll-inline-contain snap-x snap-mandatory overflow-x-auto overflow-y-hidden scroll-smooth'>
      <Container>
        <div className='flex w-full gap-2 lg:gap-4' {...slideHandlers}>
          {features.map((feature, index) => (
            <div
              key={index}
              className={clsx(
                'user-select-none w-[calc(100%_-_0.75rem)] shrink-0 cursor-pointer select-none snap-center lg:w-[calc((100%_-_3rem)_/_2)] 2xl:w-[calc((100%_-_2rem)_/_3)]',
                index === features.length - 1 && 'pr-4 lg:pr-3',
              )}
            >
              <FeatureTile {...feature} />
            </div>
          ))}
        </div>
      </Container>
    </section>
  ) : (
    <Container>
      <section className={clsx('grid grid-cols-12 gap-4')}>
        {features.map((feature, index) => (
          <div
            key={index}
            className={clsx(
              index % 5 < 3 && 'col-span-12 lg:col-span-6 2xl:col-span-4',
              index % 5 >= 3 && 'col-span-12 lg:col-span-6  2xl:col-span-6',
            )}
          >
            <FeatureTile {...feature} />
          </div>
        ))}
      </section>
    </Container>
  );
};

export default FeaturesGrid;
